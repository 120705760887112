import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {valueAccessorProvider, murmurHash, stringToColor} from '../util/util';
import {OverlayPanel, OverlayPanelModule} from 'primeng/overlaypanel';
import {AbstractControlValueAccessor} from './abstract-control-value-accessor';
import {FileUploadDialog} from './file';
import {ImageCroppedEvent, ImageCropperModule, ImageTransform} from 'ngx-image-cropper';
import {HttpClient} from '@angular/common/http';

import {COMMON_LIB_CONFIG_TOKEN, ICommonLibConfig} from '../common-lib-config';
import {FormsModule} from '@angular/forms';
import {NgIf} from '@angular/common';
import {TooltipModule} from 'primeng/tooltip';
import {ButtonModule} from 'primeng/button';
import {SharedModule} from 'primeng/api';

const BASE64_REGEX = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;


@Component({
  selector: 'app-avatar',
  template: `
    <p-overlayPanel #editorPanel [dismissable]="false"
                    [showCloseIcon]="true"
                    (onShow)="onEditorPanelOpen()"
                    (onHide)="onEditorPanelClose()">
      <ng-template pTemplate>
        <app-file-upload-dialog #fileUploadDialog></app-file-upload-dialog>
        <input style="position: absolute; left: -9999px" autofocus #focus
               (keydown.escape)="!fileUploadDialog.isShown() ? editorPanel.hide() : null; $event.stopPropagation()"
               (blur)="focus.focus()"/>
        <div class="flex align-items-center mb-2">
          <p-button icon="pi pi-folder-open" styleClass="p-button-rounded p-button-text"
                    pTooltip="Choose photo" tooltipPosition="bottom"
                    (onClick)="chooseEditorImage(fileUploadDialog)"></p-button>
          <div class="border-gray-400 border-left-1 ml-3" style="height: 2.5rem"></div>
          <p-button class="ml-3" icon="pi pi-replay" styleClass="p-button-rounded p-button-text"
                    pTooltip="Rotate counterclockwise" tooltipPosition="bottom"
                    [disabled]="!cropper.cropped"
                    (onClick)="rotate(-1)"></p-button>
          <p-button class="ml-2" icon="pi pi-refresh" styleClass="p-button-rounded p-button-text"
                    pTooltip="Rotate clockwise" tooltipPosition="bottom"
                    [disabled]="!cropper.cropped"
                    (onClick)="rotate(1)"></p-button>
          <p-button class="ml-2" icon="pi pi-trash" styleClass="p-button-rounded p-button-text"
                    pTooltip="Clear" tooltipPosition="bottom"
                    [disabled]="!cropper.cropped"
                    (onClick)="clearEditorImage()"></p-button>
          <p-button class="ml-2" icon="pi pi-sync" styleClass="p-button-rounded p-button-text"
                    pTooltip="Reset" tooltipPosition="bottom"
                    [disabled]="cropper.hash.initial === cropper.hash.current"
                    (onClick)="resetEditorImage()"></p-button>
          <div class="border-gray-400 border-left-1 ml-3" style="height: 2.5rem"></div>
          <p-button class="ml-3" label="Apply" icon="pi pi-check" styleClass="p-button-text"
                    [disabled]="cropper.hash.initial === cropper.hash.current"
                    (onClick)="applyEditorImage()"></p-button>
        </div>
        <div *ngIf="!cropper.file && !cropper.url" class="cropper-placeholder"
             (click)="chooseEditorImage(fileUploadDialog)">
          <i class="pi pi-image text-white text-5xl"></i>
        </div>
        <div class="mt-1" [class.hidden]="!cropper.file && !cropper.url">
          <image-cropper #imageCropper style="max-width: 600px; max-height: 600px" [transform]="cropper.transform"
                         output="base64"
                         format="png"
                         [imageFile]="cropper.file!"
                         [imageURL]="cropper.url!"
                         (imageCropped)="cropper.onImageCropped($event)"
                         [roundCropper]="true">
          </image-cropper>
        </div>
      </ng-template>
    </p-overlayPanel>
    <ng-container>
      <ng-container *ngIf="!value; else avatarImg">
        <div *ngIf="!initials; else initialsCircle" class="empty-avatar" [style.width]="size"
             [style.height]="size"></div>
        <ng-template #initialsCircle>
          <div class="initials" [style.width]="size" [style.height]="size" [style.font-size]="fontSize"
               [style.background-color]="initialsBg">{{ initials }}
          </div>
        </ng-template>
      </ng-container>
      <ng-template #avatarImg>
        <p-overlayPanel #op showTransitionOptions="0s" hideTransitionOptions="0s">
          <ng-template pTemplate>
            <app-avatar [ngModel]="value" size="200px"></app-avatar>
          </ng-template>
        </p-overlayPanel>
        <img [src]="base64 ? ('data:image/png;base64,' + value) : value"
             class="img" [style.width]="size" [style.height]="size"
             (mouseenter)="onImgMouseEnter(op, $event)" (mouseleave)="onImgMouseLeave(op)">
      </ng-template>
    </ng-container>
    <div *ngIf="editable" class="edit-indicator" [style.width]="size" [style.height]="size">
      <i class="text-2xl pi pi-camera"></i>
    </div>
    <div *ngIf="editable" #popupEditorTarget
         style="height: 0; width: 0; position: absolute; bottom: 0; left: calc(50% - 15px)">
    </div>
  `,
  host: {
    'class': 'avatar',
    '[class.editable]': 'editable'
  },
  styles: [
    `

      :host {
        position: relative;
      }

      :host.editable {
      }

      :host:hover {
        filter: brightness(1.2);
      }


      .empty-avatar {
        border-radius: 50%;
        background-color: #9E9E9E;
        display: inline-block;
      }

      .initials {
        cursor: default;
        border-radius: 50%;
        color: #FFF;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }

      .img {
        border-radius: 50%;
      }


      .edit-indicator {
        cursor: pointer;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: opacity .3s;
        color: #f8f9fa;
        border-radius: 50%;
      }

      .edit-indicator:hover {
        background-color: rgba(0, 0, 0, 0.4);
        opacity: 1;
      }

      .cropper-placeholder {
        height: 300px;
        width: 300px;
        background-color: var(--gray-400);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: opacity .3s;

      }

      .cropper-placeholder:hover {
        opacity: .85;
      }
    `
  ],
  providers: [valueAccessorProvider(AvatarComponent)],
  standalone: true,
  imports: [
    OverlayPanelModule, SharedModule, FileUploadDialog, ButtonModule, TooltipModule, NgIf, ImageCropperModule,
    FormsModule
  ]
})
export class AvatarComponent extends AbstractControlValueAccessor<string> implements OnChanges, OnDestroy {
  @Input() name?: string;
  @Input() size = '2rem';
  @Input() fontSize = '1rem';
  @Input() showPreview = true;
  @Input() editable = false;

  @ViewChild('editorPanel', {read: OverlayPanel}) editorPanel!: OverlayPanel;
  @ViewChild('popupEditorTarget') popupEditorTarget!: ElementRef;

  initials?: string | null;
  base64 = false;
  initialsBg!: string;
  popupTimeout: any = null;

  cropper: {
    originalUrl: string | null;
    file: File | null;
    url: string | null;
    cropped: string | null;
    hash: {
      initial: number;
      current: number;
    }
    transform: ImageTransform;
    onImageCropped: (event: ImageCroppedEvent) => void;
  } = {
    originalUrl: null,
    file: null,
    url: null,
    cropped: null,
    hash: {
      initial: 0,
      current: 0
    },
    transform: {rotate: 0},
    onImageCropped: (event: ImageCroppedEvent) => {
      this.cdr.detach();
      this.value = this.cropper.cropped = event.base64!;
      if (!this.cropper.hash.initial && !!this.cropper.originalUrl) {
        this.cropper.hash.initial = murmurHash(event.base64!, 100);
      }
      this.cropper.hash.current = murmurHash(event.base64!, 100);
      this.cdr.reattach();
      this.cdr.detectChanges();
    }
  };

  constructor(private http: HttpClient,
              private cdr: ChangeDetectorRef,
              @Inject(COMMON_LIB_CONFIG_TOKEN) private config: ICommonLibConfig) {
    super();
  }

  @HostListener('click', ['$event'])
  onClick(event: PointerEvent) {
    if (!this.isDisabled && this.editable) {
      this.clearPopupTimeout();
      this.editorPanel.toggle(event, this.popupEditorTarget.nativeElement);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initials = this.getInitials();
    if (this.initials) {
      this.initialsBg = stringToColor(this.initials);
    }
  }

  isBase64(): boolean {
    return !!this.value && BASE64_REGEX.test(this.value);
  }

  getInitials(): string | null {
    if (this.name == null || !this.name.trim()) {
      return null;
    }
    const parts = this.name.trim().replace(/\s\s+/g, ' ').split(' ');
    if (!parts.length) {
      return null;
    }
    return parts[0][0] + (parts.length > 1 ? parts[parts.length - 1][0] : '');
  }

  onImgMouseEnter(op: OverlayPanel, event: any): void {
    this.clearPopupTimeout();
    if (this.showPreview) {
      if (!op.overlayVisible) {
        this.popupTimeout = setTimeout(() => {
          op.show(event);
        }, 300);
      }
    }
  }

  onImgMouseLeave(op: OverlayPanel): void {
    this.clearPopupTimeout();
    if (this.showPreview) {
      if (op.overlayVisible) {
        op.hide();
      }
    }
  }

  clearPopupTimeout(): void {
    if (this.popupTimeout) {
      clearTimeout(this.popupTimeout);
      this.popupTimeout = null;
    }
  }

  ngOnDestroy(): void {
    this.clearPopupTimeout();
  }

  override writeValue(value: any) {
    super.writeValue(value);
    this.base64 = this.isBase64();
  }

  chooseEditorImage(fileUploadDialog: FileUploadDialog): void {
    fileUploadDialog.show({
      mode: 'choose',
      header: 'Choose image for avatar',
      accept: 'image/*',
      onChoose: (file: File) => {
        this.cropper.transform = {rotate: 0};
        this.cropper.url = null;
        this.cropper.cropped = null;
        this.cropper.hash.current = 0;
        this.cropper.file = file;
      }
    });
  }

  clearEditorImage(): void {
    this.cropper.transform = {rotate: 0};
    this.cropper.file = null;
    this.cropper.url = null;
    this.cropper.cropped = null;
    this.cropper.hash.current = 0;
    this.value = null;
  }

  resetEditorImage(): void {
    this.cropper.transform = {rotate: 0};
    this.cropper.url = null;
    this.cropper.file = null;
    this.cropper.cropped = null;
    this.cropper.hash.current = 0;
    this.value = this.cropper.originalUrl;
    setTimeout(() => this.cropper.url = this.cropper.originalUrl);

  }

  async applyEditorImage(): Promise<any> {
    if (this.config.imageServiceUrl) {
      if (this.cropper.cropped) {
        const f = await fetch(this.cropper.cropped);
        const name = `${!!this.name ? this.name!.replace(' ', '-') : 'Unnamed'}.png`;
        const file = new File([await f.blob()], name, {
          type: 'image/png'
        });
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('mimetype', 'image/png'); // TODO: remove
        return this.http.post<{ url: string; }>(this.config.imageServiceUrl, formData)
          .subscribe((response) => {
            this.value = this.cropper.originalUrl = response.url;
            this.onModelChange(this.value);
            this.editorPanel.hide();
          });
      } else {
        this.value = this.cropper.originalUrl = null;
        this.onModelChange(this.value);
        this.editorPanel.hide();
      }
    }
  }

  onEditorPanelOpen(): void {
    this.cropper.transform = {rotate: 0};
    this.cropper.hash.initial = 0;
    this.cropper.hash.current = 0;
    this.cropper.file = null;
    this.cropper.cropped = null;
    this.cropper.url = this.value!;
    this.cropper.originalUrl = this.value!;
  }

  onEditorPanelClose(): void {
    this.cropper.file = null;
    this.cropper.cropped = null;
    this.cropper.url = null;
    this.value = this.cropper.originalUrl;
  }

  rotate(rotation: -1 | 1): void {
    this.cropper.transform = {rotate: this.cropper.transform.rotate! + (90 * rotation)};
  }
}
