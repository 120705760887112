import {Component, Input, OnChanges, Optional, SimpleChanges} from '@angular/core';
import {ColumnsInfoService, TFrozenColumn} from './column-header.component';
import {Params, Router, RouterLink} from '@angular/router';
import {IconDefinition} from '@fortawesome/free-brands-svg-icons';
import {get, isArray, isFunction} from 'lodash';

import {AbstractEmbeddableComponent} from '../abstract-embeddable-component';
import {SanitizePipe} from '../sanitize.pipe';
import {AsArrayPipe} from '../as-array.pipe';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {TooltipModule} from 'primeng/tooltip';
import {TooltipOnOverflowDirective} from '../tooltip-on-overflow.directive';
import {ListboxModule} from 'primeng/listbox';
import {SharedModule} from 'primeng/api';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {DatePipe, NgClass, NgFor, NgIf, NgSwitchCase} from '@angular/common';
import {TableModule} from 'primeng/table';

export interface IIconLinkOption {
  field: string | ((rowData: any) => any);
  icon: IconDefinition;
  tooltip: string;
  protocol?: string;
  isInternal?: boolean;
}

export type TTableCellType =
  'simple'
  | 'boolean'
  | 'date'
  | 'datetime'
  | 'stringArray'
  | 'externalLink'
  | 'internalLink'
  | 'iconLinks'
  | 'custom';

@Component({
  selector: 'app-td',
  template: `
    <ng-template #template>
      <td [style.z-index]="!!frozen ? 1 : 'auto'"
          pFrozenColumn [frozen]="!!frozen"
          [class.mt-last-left-frozen-column]="frozen === 'lastLeft'"
          [class.mt-first-right-frozen-column]="frozen === 'firstRight'"
          [alignFrozen]="frozen === 'firstRight' || frozen === 'right' ? 'right' : undefined!"
          [style]="tdStyle"
          [ngClass]="tdStyleClass">
        <div class="flex align-items-center"
             [class.justify-content-end]="align === 'right'"
             [class.justify-content-center]="align === 'center' || type === 'boolean'"
             [style]="_cellStyle"
             [class]="cellStyleClass">
          @if (isEmpty()) {
            @if (nullSymbol == null) {
              <div class="border-bottom-1 text-gray-400" style="width: 25px"></div>
            } @else {
              {{ nullSymbol }}
            }
          } @else {
            @switch (type) {
              @case ('stringArray') {
                <p-overlayPanel #opStringArray styleClass="string-array-popup" [dismissable]="true"
                                [showCloseIcon]="false">
                  <ng-template pTemplate>
                    <p-listbox [class.string-array-links]="!!stringArrayRoute"
                               [options]="rowData?.[field] || []"
                               [filter]="true"
                               [listStyle]="{'max-height':'250px'}"
                               [readonly]="false" (onClick)="onStringArrayItemClick($event)">
                    </p-listbox>
                  </ng-template>
                </p-overlayPanel>
                <div class="mt-chip-container" (click)="rowData?.[field] ? opStringArray.toggle($event) : null">
                  @for (item of rowData?.[field] | asArray; track item) {
                    <div class="mt-chip">{{ item }}</div>
                  }
                </div>
              }
              @case ('externalLink') {
                <div [class.mt-overflow-ellipsis]="tooltipOnOverflow" appTooltipOnOverflow
                     [showTooltip]="tooltipOnOverflow">
                  <a [href]="href || rowData?.[linkUrlField || '']" class="mt-link"
                     target="_blank">{{ rowData?.[field] }}</a>
                </div>
                <div *ngSwitchCase="'internalLink'"
                     [class.mt-overflow-ellipsis]="tooltipOnOverflow" appTooltipOnOverflow
                     [showTooltip]="tooltipOnOverflow">
                  <a *ngIf="filterLinkOptions && rowData?.[field] != null" [routerLink]="filterLinkOptions.link"
                     [queryParams]="filterLinkOptions.queryParams">
                    <i class="pi pi-filter mt-filter-link text-sm"></i>
                  </a>
                  <a class="mt-link" [routerLink]="routerLinkOptions?.link"
                     [queryParams]="routerLinkOptions?.queryParams">
                    {{ rowData?.[field] }}
                  </a>
                </div>
              }
              @case ('iconLinks') {
                <a *ngFor="let link of iconLinksOptions || []; let i = index"
                   class="mt-link"
                   [style.pointer-events]="!getIconLinkValue(link) ? 'none' : 'auto'"
                   [href]="(getIconLinkValue(link) ? ((link.protocol ? (link.protocol + ':')  : '') + getIconLinkValue(link)) : null) | sanitize"
                   [class.ml-2]="i > 0"
                   [class.opacity-50]="!getIconLinkValue(link)"
                   [class.text-gray-600]="!getIconLinkValue(link)"
                   [target]="link.isInternal ? '_self' : '_blank'" [pTooltip]="link.tooltip" tooltipPosition="bottom">
                  <fa-icon [icon]="link.icon"></fa-icon>
                </a>
              }
              @case ('boolean') {
                <i class="pi"
                   [class.pi-check-circle]="(value !== undefined ? value : rowData?.[field]) === true"
                   [class.pi-times-circle]="(value !== undefined ? value : rowData?.[field]) === false">
                </i>
              }
              @case ('date') {
                <div>
                  {{ value !== undefined ? value : rowData?.[field] | date: 'MM/dd/yy' }}"
                </div>
              }
              @case ('datetime') {
                <div>
                  {{ value !== undefined ? value : rowData?.[field] | date: 'MM/dd/yy hh:mm:ss a' }}"
                </div>
              }
              @case ('custom') {
                <ng-content></ng-content>
              }
              @default {
                <div [class.mt-overflow-ellipsis]="tooltipOnOverflow" appTooltipOnOverflow
                     [showTooltip]="tooltipOnOverflow">
                  {{ value !== undefined ? value : rowData?.[field] }}
                </div>
              }
            }
          }
        </div>
      </td>
    </ng-template>
  `,
  styles: [
    `

      ::ng-deep .string-array-popup .p-overlaypanel-content {
        padding: .5rem;
      }

      ::ng-deep .string-array-popup .p-listbox {
        border: none;
      }

      ::ng-deep .string-array-popup p-listbox .p-listbox-item {
        cursor: default;

        &.p-highlight {
          color: inherit;
          background: inherit;
        }
      }

      ::ng-deep .string-array-popup p-listbox.string-array-links .p-listbox-item {
        cursor: pointer;
        color: var(--primary-color) !important;
      }
    `
  ],
  standalone: true,
  imports: [
    TableModule, NgClass, OverlayPanelModule, SharedModule, ListboxModule, TooltipOnOverflowDirective, NgSwitchCase,
    NgIf, RouterLink, NgFor, TooltipModule, FaIconComponent, DatePipe, AsArrayPipe, SanitizePipe
  ]
})
export class TableCellComponent extends AbstractEmbeddableComponent implements OnChanges {
  @Input() type?: TTableCellType
  @Input({required: true}) field!: string;
  @Input() linkUrlField?: string;
  @Input() href?: string;
  @Input() routerLinkOptions?: { link: Array<string>; queryParams?: Params };
  @Input() rowData?: any;
  @Input() value?: any;
  @Input() tdStyle: { [prop: string]: any } = {};
  @Input() tdStyleClass?: string;
  @Input() cellStyle: { [prop: string]: any } = {};
  @Input() cellStyleClass?: string;
  @Input() tooltipOnOverflow = true;
  @Input() frozen?: TFrozenColumn;
  @Input() iconLinksOptions?: Array<IIconLinkOption>;
  @Input() align: 'left' | 'right' | 'center' = 'left';
  @Input() stringArrayRoute?: string | ((value: any, rowData: any) => string);
  @Input() filterLinkOptions?: { link: Array<string>; queryParams?: Params };
  @Input() showNull = true;
  @Input() nullSymbol: string | null = null;

  protected _cellStyle: { [prop: string]: any } = {};

  constructor(private router: Router,
              @Optional() private columnsInfo: ColumnsInfoService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const widthStyle = this.columnsInfo?.getWidthStyle(this.field) || {};
    this._cellStyle = {
      ...widthStyle,
      ...this.cellStyle
    };
    this.frozen = this.columnsInfo?.getFrozen(this.field);
  }

  onStringArrayItemClick(event: any): void {
    if (this.stringArrayRoute) {
      if (isFunction(this.stringArrayRoute)) {
        this.router.navigateByUrl(this.stringArrayRoute(event.value, this.rowData));
      } else {
        this.router.navigateByUrl(this.stringArrayRoute.replace('{id}', event.value));
      }
    }
  }

  getIconLinkValue(iconLinksOption: IIconLinkOption): any {
    return isFunction(iconLinksOption.field)
      ? iconLinksOption.field(this.rowData)
      : get(this.rowData, iconLinksOption.field)
  }

  isEmpty(): boolean {
    return this.showNull
      && ((this.value == null && this.rowData?.[this.field] == null) || (isArray(this.rowData?.[this.field]) && !this.rowData?.[this.field].length));
  }
}


@Component({
  selector: 'app-td-selector',
  template: `
    <ng-template #template>
      <td style="width: 45px; max-width: 45px; z-index: 1" pFrozenColumn>
        <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
      </td>
    </ng-template>
  `,
  standalone: true,
  imports: [TableModule]
})
export class TableCellSelectorComponent extends AbstractEmbeddableComponent {
  @Input() rowData: any;
}
