import {inject} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {clearStorageKeys, getStorageItem, isAllNullOrUndefined, parseJson, setStorageItem} from '../util/util';

export enum EStateStrategy {
  Storage,
  QueryParams
}

export class StateAdapter {
  router = inject(Router);
  activatedRoute = inject(ActivatedRoute);
  saveState(key: string, data: any, strategy: EStateStrategy = EStateStrategy.QueryParams): void {
    if (strategy === EStateStrategy.Storage) {
      if (!isAllNullOrUndefined(data)) {
        setStorageItem(key, data);
      } else {
        clearStorageKeys(key);
      }
    } else {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: {[key]: !isAllNullOrUndefined(data) ? encodeURIComponent(JSON.stringify(data)) : null},
        queryParamsHandling: 'merge',
        replaceUrl: true
      });
    }
  }

  restoreState<T>(key: string, strategy: EStateStrategy = EStateStrategy.QueryParams): T | null | undefined {
    if (strategy === EStateStrategy.Storage) {
      return getStorageItem<T>(key);
    } else {
      let result = undefined;
      if (this.activatedRoute.snapshot.queryParams[key]) {
        try {
          result = parseJson(decodeURIComponent(this.activatedRoute.snapshot.queryParams[key]));
        } catch (e) {
        }
        if (!result) {
          this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {[key]: null},
            queryParamsHandling: 'merge',
            replaceUrl: true
          });
        }
      }
      return result;
    }
  }
}
