import {InjectionToken} from '@angular/core';

export const COMMON_LIB_CONFIG_TOKEN = new InjectionToken<ICommonLibConfig>('CommonLibConfig')
export interface ICommonLibConfig {
  apiUrl: string;
  imageServiceUrl: string;
  fileStorageUrl: string;
  auth: {
    googleAuthUrl: string;
    passwordAuthUrl: string;
    refreshTokenUrl: string;
  }
}
