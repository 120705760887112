import {Pipe, PipeTransform} from '@angular/core';
import {isArray} from 'lodash';

@Pipe({
    name: 'asArray',
    standalone: true
})
export class AsArrayPipe implements PipeTransform {
  transform(value: any): Array<any> {
    if (value == null || !isArray(value)) {
      return [];
    }
    return value as Array<any>;
  }
}
