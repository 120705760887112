import {Injectable} from '@angular/core';
import {CONTRACT_TYPES, TContractType} from '../../api/shared/common';
import {memoize} from 'lodash';
import {Info} from 'luxon';


@Injectable({
  providedIn: 'root'
})
export class ConstantsProviderService {
  archivableStatus = [{label: 'Active', value: false}, {label: 'Archived', value: true}];

  contractTypeIcon: (type: TContractType) => string | null = memoize((type) => {
    const t = CONTRACT_TYPES.find((t) => t === type);
    return !!t ? `assets/images/team/${t}.svg` : null;
  });

  weekDays(unit: 'short' | 'long' = 'short'): Array<string> {
    return Info.weekdays(unit);
  }
}

