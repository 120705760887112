import {IIdentified} from './common';

export enum ESortDir {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface ISort {
  field: string;
  dir?: ESortDir;
}

export const QUERY_LOGICAL = <const>[
  'and', 'or', 'not'
];
export const QUERY_OPERATOR = <const>[
  'startsWith', 'contains', 'notContains', 'endsWith',
  'equals', 'notEquals',
  'in',
  'lt', 'lte', 'gt', 'gte',
  'between',
  'fulltext',
  'isNull',
  'isNotNull'
];

export type TQueryLogical = typeof QUERY_LOGICAL[number];
export type TQueryOperator = typeof QUERY_OPERATOR[number];
export type TQueryValue = string | number | boolean;

export type TQueryPredicate = {
  field: string;
  operator: TQueryOperator;
  value: TQueryValue | TQueryValue[];
};
export type TQueryExpression = {
  logical: TQueryLogical;
  predicates: Array<TQuery>;
};
export type TQuery = TQueryPredicate | TQueryExpression;


export function isInstanceOfQueryPredicate(predicateOrExpression: TQuery): predicateOrExpression is TQueryPredicate {
  return 'field' in predicateOrExpression;
}

export function isInstanceOfQueryExpression(predicateOrExpression: TQuery): predicateOrExpression is TQueryExpression {
  return 'logical' in predicateOrExpression;
}

export interface ISearchRequest<P = any> {
  offset?: number;
  limit?: number;
  sort?: Array<ISort>;
  query?: TQuery;
  search?: string;
  fields?: Array<string>;
  isDistinct?: boolean;
  param?: P;
}


export interface ISearchResponse<T, ED extends object = any> extends Omit<ISearchRequest, 'query' | 'search'> {
  results: Array<T>;
  total: number;
  extraData?: ED;
}

export interface IDeletePayload {
  ids: Array<string>;
}

export type TWithId<T> = Partial<T> & IIdentified;
