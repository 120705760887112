import {
  Component,
  Directive,
  EventEmitter, inject,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges
} from '@angular/core';
import {isString, startCase} from 'lodash';
import {ControlContainer, FormControl, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {IconDefinition} from '@fortawesome/free-brands-svg-icons';
import {ControlErrorComponent} from '../control-error.component';
import {UploadAttachmentsComponent} from '../file';
import {ChipsModule} from 'primeng/chips';
import {TreeSelectComponent} from '../tree-select.component';
import {MultiSelectModule} from 'primeng/multiselect';
import {SharedModule} from 'primeng/api';
import {DropdownModule} from 'primeng/dropdown';
import {CheckboxModule} from 'primeng/checkbox';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {CalendarModule} from 'primeng/calendar';
import {InputNumberModule} from 'primeng/inputnumber';
import {NullableDirective} from '../nullable.directive';
import {InputTextModule} from 'primeng/inputtext';
import {TooltipModule} from 'primeng/tooltip';
import {RippleModule} from 'primeng/ripple';
import {ButtonModule} from 'primeng/button';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {AsyncPipe, NgClass, NgIf} from '@angular/common';
import {OnDemandResourceLoaderService} from '../../services/resources/on-demand-resource-loader.service';


@Directive()
export abstract class AbstractFieldFormControl<T extends {}> {
  @Input({required: true}) field!: keyof T;
}

export type TFormControlWrapperType = 'input' | 'dropdown' | 'multiselect' | 'chips' | 'treeSelect' | 'attachments';

@Component({
  selector: 'app-form-control-wrapper',
  template: `
    <div class="label-container" [class.checkbox]="dataType === 'boolean'">
      <label class="label" [class.mt-required-field]="isRequired()"
             [class.text-gray-400]="formControl ? formControl.disabled : false">
        <fa-icon *ngIf="labelIcon" [icon]="labelIcon" class="mr-2"></fa-icon>
        {{ label }}
      </label>
      <button *ngIf="showAddButton" pButton pRipple type="button" icon="pi pi-plus font-bold"
              class="p-button-rounded p-button-text" (click)="addButtonClick.emit($event)"
              [pTooltip]="'Add New ' + label" tooltipPosition="bottom"></button>
    </div>
    @switch (controlType) {
      @case ('input') {
        <span [class.p-input-icon-left]="!!icon">
          <i *ngIf="icon" class="cursor-pointer" [ngClass]="icon"
             [class.p-disabled]="!!iconDisabled"
             (click)="onIconClick()" [pTooltip]="iconTooltip!" tooltipPosition="bottom"></i>
          @switch (dataType) {
            @case ('string') {
              <input [formControl]="formControl" pInputText type="text" nullable/>
            }
            @case ('currency') {
              <p-inputNumber [formControl]="formControl" mode="currency" currency="USD" locale="en-US"
                             [showClear]="true">
              </p-inputNumber>
            }
            @case ('number') {
              <p-inputNumber [formControl]="formControl"
                             [minFractionDigits]="minFractionDigits!" [maxFractionDigits]="maxFractionDigits!"
                             [min]="min!" [max]="max!"
                             [prefix]="prefix!" [suffix]="suffix!"
                             [showButtons]="showButtons!"
                             [showClear]="true" [placeholder]="placeholder">
              </p-inputNumber>
            }
            @case ('date') {
              <p-calendar [formControl]="formControl" [showIcon]="true" appendTo="body"
                          [placeholder]="placeholder"></p-calendar>
            }
            @case ('text') {
              <textarea [formControl]="formControl" pInputTextarea [autoResize]="true"
                        [style.min-height]="'46px'" nullable></textarea>
            }
            @case ('boolean') {
              <p-checkbox [formControl]="formControl" [binary]="true"></p-checkbox>
            }
          }
        </span>
      }
      @case ('dropdown') {
        <span [class.p-input-icon-left]="!!icon">
          <i *ngIf="icon" class="cursor-pointer" [ngClass]="icon"
             [class.p-disabled]="!!iconDisabled"
             (click)="onIconClick()" [pTooltip]="iconTooltip!" tooltipPosition="bottom"></i>
          <p-dropdown [formControl]="formControl"
                      [editable]="dropdownEditable"
                      [placeholder]="label"
                      [autoDisplayFirst]="false"
                      appendTo="body"
                      [options]="onDemandOptions ? $any(onDemandLoader.observe(onDemandOptions) | async) : options"
                      [optionLabel]="optionLabel!"
                      [optionValue]="optionValue!"
                      [filter]="optionsFilter"
                      [filterBy]="optionsFilterBy!"
                      [showClear]="dropdownEditable || !isRequired()"
                      [virtualScroll]="virtualScroll"
                      [virtualScrollItemSize]="virtualScrollItemSize"
                      nullable>
            <ng-template let-item pTemplate="selectedItem">
              <div class="flex align-items-center" *ngIf="formControl.value != null && !!item">
                <img *ngIf="optionIcon" [src]="getOptionIcon(item)" width="14" class="mr-1"/>
                <div>{{ item[optionLabel || 'label'] ?? item }}</div>
              </div>
            </ng-template>
            <ng-template let-item pTemplate="item">
               <div class="flex align-items-center">
                 <img *ngIf="optionIcon" [src]="getOptionIcon(item)" width="24" class="mr-1"/>
                 <div>{{ item[optionLabel || 'label'] ?? item }}</div>
               </div>
            </ng-template>
          </p-dropdown>
        </span>
      }
      @case ('multiselect') {
        <p-multiSelect [formControl]="formControl"
                       display="chip"
                       [maxSelectedLabels]="1000000"
                       appendTo="body"
                       [options]="onDemandOptions ? $any(onDemandLoader.observe(onDemandOptions) | async) : options"
                       [optionLabel]="optionLabel!"
                       [optionValue]="optionValue!"
                       [filter]="optionsFilter"
                       [filterBy]="optionsFilterBy!"
                       [showToggleAll]="!virtualScroll"
                       [showClear]="true"
                       [virtualScroll]="virtualScroll"
                       [virtualScrollItemSize]="virtualScrollItemSize"
                       nullable>
        </p-multiSelect>
      }
      @case ('treeSelect') {
        <app-tree-select [formControl]="formControl"
                         [filter]="optionsFilter"
                         [options]="options"
                         nullable>
        </app-tree-select>
      }
      @case ('chips') {
        <p-chips [formControl]="formControl" [showClear]="true" nullable></p-chips>
      }
      @case ('attachments') {
        <app-upload-attachments [formControl]="formControl"></app-upload-attachments>
      }
    }
    <app-control-error [control]="formControl"></app-control-error>
  `,
  styles: [
    `
      .label-container {
        display: inline-block;
        margin-bottom: 0.5rem;
      }

      .label-container button {
        width: 1rem;
        height: 1rem;
        margin-left: 0.5rem;
      }

      .label {
        display: inline-block;
      }

      .label-container.checkbox {
        margin-bottom: .2rem;
        margin-right: .5rem;
      }

      :host ::ng-deep .p-input-icon-left > i:first-of-type {
        z-index: 1;
      }

      :host ::ng-deep .p-input-icon-left .p-dropdown .p-dropdown-label {
        padding-left: 2.5rem;
      }
    `
  ],
  standalone: true,
  imports: [
    NgIf,
    FaIconComponent,
    ButtonModule,
    RippleModule,
    TooltipModule,
    NgClass,
    FormsModule,
    InputTextModule,
    NullableDirective,
    ReactiveFormsModule,
    InputNumberModule,
    CalendarModule,
    InputTextareaModule,
    CheckboxModule,
    DropdownModule,
    SharedModule,
    MultiSelectModule,
    TreeSelectComponent,
    ChipsModule,
    UploadAttachmentsComponent,
    ControlErrorComponent,
    AsyncPipe,
  ],
})
export class FormControlWrapperComponent implements OnInit, OnChanges {
  @Input() controlType: TFormControlWrapperType = 'input';
  @Input() dataType: 'string' | 'text' | 'date' | 'number' | 'currency' | 'boolean' = 'string';
  @Input() controlName!: string;

  @Input() label?: string;
  @Input() labelIcon?: IconDefinition;
  @Input() placeholder?: string;

  private _icon?: string;
  @Input() set icon(value: string | undefined) {
    this._icon = value;
  }

  get icon(): string | undefined {
    return this._icon || (this.iconIsLink ? 'pi pi-external-link' : undefined);
  }

  private _iconDisabled?: boolean;
  @Input() set iconDisabled(value: boolean | undefined) {
    this._iconDisabled = value;
  }

  get iconDisabled(): boolean {
    return this._iconDisabled || this.iconIsLink ? this.formControl?.invalid || !this.value : false;
  }

  @Input() iconTooltip?: string;
  @Input() iconIsLink = false;
  @Input() linkValue?: string;
  @Output() iconClick: EventEmitter<any> = new EventEmitter<any>();

  @Input() dropdownEditable = false;
  @Input() options: Array<any> = [];
  @Input() onDemandOptions?: string;
  @Input() optionLabel?: string;
  @Input() optionValue?: string;
  @Input() optionsFilter = false;
  @Input() optionsFilterBy?: string;
  @Input() optionIcon?: ((item: any) => string | null) | string;

  @Input() virtualScroll = false
  @Input() virtualScrollItemSize = 39;

  @Input() showButtons = false;
  @Input() prefix?: string;
  @Input() suffix?: string;
  @Input() minFractionDigits?: number;
  @Input() maxFractionDigits?: number;
  @Input() min?: number;
  @Input() max?: number;

  @Input() showAddButton = false;
  @Output() addButtonClick: EventEmitter<any> = new EventEmitter<any>();

  formControl!: FormControl;
  protected onDemandLoader = inject(OnDemandResourceLoaderService);

  constructor(private controlContainer: ControlContainer, @Optional() private parent: AbstractFieldFormControl<{}>) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.controlName) {
      this.setFormControl(changes.controlName.currentValue);
    }
    if (changes.label) {
      this.label = changes.label.currentValue;
    }
    if (changes.optionValue) {
      this.optionLabel = this.optionValue;
    }
    if (changes.optionLabel) {
      this.optionLabel = changes.optionLabel.currentValue;
    }
  }

  private setFormControl(controlName: string): void {
    if (this.controlContainer) {
      this.formControl = this.formControl ?? this.controlContainer!.control!.get(controlName)! as FormControl;
      this.label = this.label ?? startCase(controlName);
    }
  }

  ngOnInit(): void {
    if (this.parent) {
      this.setFormControl(this.parent.field);
    }
  }

  get value(): any {
    return this.formControl?.value;
  }

  setValueAndMark(value: any): void {
    this.formControl?.setValue(value);
    this.formControl?.markAsDirty();
    this.formControl?.markAsTouched();
  }

  isRequired(): boolean {
    return this.formControl?.hasValidator(Validators.required);
  }

  onIconClick(): void {
    if (this.iconIsLink) {
      window.open(this.controlType === 'dropdown' ? this.linkValue : this.value, '_blank');
    } else {
      this.iconClick.emit(this.value);
    }
  }

  isArrayType(): boolean {
    return (['multiselect', 'chips'] as Array<TFormControlWrapperType>).includes(this.controlType);
  }

  getOptionIcon(item: any): string | null {
    if (!item || !this.optionIcon) {
      return null;
    }
    if (isString(this.optionIcon)) {
      return item[this.optionIcon];
    } else {
      return this.optionIcon(item);
    }
  }
}
