import {ChangeDetectorRef, Directive, inject, Input, OnChanges, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AbstractEntityEditorBase} from './abstract-entity-editor-base';
import {catchError, finalize, Observable, of} from 'rxjs';
import {ISearchRequest, ISearchResponse, TQueryExpression} from '../../../api/shared/search-api';
import {map} from 'rxjs/operators';
import {IIdentified} from '../../../api/shared/common';
import {isInstanceOfDraftable, TControlsOf} from '../../types';
import {cloneDeep, forEach} from 'lodash';
import {IEntityResource} from '../../services/resources/entity-resource';
import {Alert} from '../../util/alert';
import {deepDifference} from '../../util/util';


export interface IEntityEditorParam {
  id: string | null;
  duplicate?: boolean;
  data?: any;
}


@Directive()
export abstract class AbstractEntityEditor<S extends {}, T extends S & IIdentified> extends AbstractEntityEditorBase<S, TControlsOf<S>> implements OnChanges {
  @Input() param!: IEntityEditorParam;
  protected saveEntity!: S;
  protected representativeField = 'name';
  protected isDraftable = false;
  protected significantDraftFields: Array<keyof T> = [];
  protected _entity: T | null = null;
  protected _entityFetchFailed = false;
  protected fb = inject(FormBuilder);
  protected cdr = inject(ChangeDetectorRef);

  constructor(protected api: Partial<IEntityResource<S, T>>, protected entityName: string) {
    super();
  }

  get entityFetchFailed() {
    return this._entityFetchFailed;
  }

  get entity(): T | null {
    return this._entity;
  }

  override get title(): string {
    return this._title ?? `Edit ${this.entityName}: Unknown`;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.param) {
      this.onChangeParam();
    }
  }

  protected onChangeParam(): void {
    this.reset();
    if (!this.isNew()) {
      this.load();
    } else {
      this.newEntity();
    }
  }

  reset(): void {
    if (this.form) {
      setTimeout(() => {
        this._entityFetchFailed = false;
        this._applyAttempted = false;
        this.form = null;
      });
    }
  }

  protected onFetchFailed(): void {
    this.form = null;
    this._entity = null;
    this._title = `Edit ${this.entityName}: Unknown`;
  }

  load(): void {
    if (this.api.getEntity) {
      this._loading = true;
      this.api.getEntity(this.param.id!)
        .pipe(
          finalize(() => this._loading = false),
          catchError(() => {
            this._entityFetchFailed = true;
            this.onFetchFailed();
            return of();
          })
        )
        .subscribe((entity) => {
          this.setEntity(entity);
        });
    }
  }

  protected newEntity(): void {
    this.setEntity({} as T);
  }

  protected setEntity(entity: T): void {
    this._entity = entity;
    this.buildForm();
    this.cdr.detectChanges();
    this.saveEntity = cloneDeep(this.isNew() ? this.getData() : this._entity);
    // this.removeValidatorsIfDraft();
    setTimeout(() => this.setTitle());
  }


  override isDataChanged(): boolean {
    if (!this.form) {
      return false;
    }
    return this.isDirty() && deepDifference(this.getData(), this.saveEntity, true);
  }


  override apply(onApply: (result: T) => void): void {
    this._loading = true;
    const data = this.getData();
    const createOrUpdate = this.isNew() || this.param.duplicate
      ? (this.api.createEntity ? this.api.createEntity(data) : undefined)
      : (this.api.updateEntity ? this.api.updateEntity(this.param.id!, data) : undefined);
    if (createOrUpdate) {
      createOrUpdate
        .pipe(
          finalize(() => this._loading = false)
        )
        .subscribe((response) => {
          this.onApplyDone(response);
          onApply(response);
        });
    }
  }

  abstract buildForm(): void;

  isNew(): boolean {
    return this.param.id === null;
  }

  protected setTitle(): void {
    this._title = `${this.isNew() ? 'New ' : (this.param.duplicate ? 'Duplicate ' : 'Edit ')}`;
    this._title += `${this.entityName}${this.isNew() ? '' : ': ' + this.entryName()}`;
    this._title += this.isDraft() ? ' (Draft)' : '';
  }

  entryName(): string | null {
    return ((this.entity || {}) as any)[this.representativeField] ?? '';
  }

  override isValid(): boolean {
    if (this.isDraftable && (this.isNew() || this.isDraft())) {
      let invalid = false;
      const doCheck = (formGroup: FormGroup) => {
        forEach(Object.keys(formGroup.controls), (field) => {
          const control = formGroup.get(field);
          if (control instanceof FormControl) {
            if (this.significantDraftFields.includes(field as any) && control.invalid) {
              invalid = true;
              return false;
            }
          } else if (control instanceof FormGroup) {
            doCheck(control);
          }
          return true;
        });
      };
      doCheck(this.form!);
      return !invalid;
    }
    return super.isValid();
  }

  override canSubmit(): boolean {
    if (!this.entity) {
      return false;
    }
    return super.canSubmit();
  }

  override canPerformApply(): boolean {
    if (!this.entity) {
      return false;
    }
    return super.canPerformApply();
  }

  onApplyDone(entity: T): void {
    Alert.message({
      severity: 'success',
      summary: 'Success',
      detail: `${this.entityName} has been ${this.isNew() ? 'created' : (this.param.duplicate ? 'duplicated' : 'updated')}`
    });
    this._entity = entity;
    this.setTitle();
    this._applyAttempted = false;
    if (this.form) {
      this.touchAllFormFields(this.form!, true);
      this.form!.markAsPristine();
    }
  }

  getCheckExists(
    api: (searchRequest: ISearchRequest) => Observable<ISearchResponse<T>>, field: keyof T
  ): (value: any) => Observable<boolean> {
    return (value: any) => {
      if (value == null) {
        return of(false);
      }
      const request: ISearchRequest = {
        limit: 1,
        offset: 0,
        query: {
          logical: 'and',
          predicates: [
            {field: field as string, operator: 'equals', value}
          ]
        }
      };
      if (!this.isNew() && !this.param.duplicate) {
        (request.query as TQueryExpression).predicates.push({field: 'id', operator: 'notEquals', value: this.param.id!})
      }
      return api(request).pipe(
        map((response) => response.total > 0)
      );
    }
  }

  isDraft(): boolean {
    return this.isDraftable && isInstanceOfDraftable(this.entity) && this.entity.isDraft;
  }

  protected removeValidatorsIfDraft(): void {
    if (this.isDraftable && (this.isNew() || this.isDraft())) {
      const doRemove = (formGroup: FormGroup) => {
        Object.keys(formGroup.controls).forEach((field) => {
          const control = formGroup.get(field);
          if (control instanceof FormControl) {
            if (!this.significantDraftFields.includes(field as any)) {
              control.removeValidators([Validators.required]);
            }
          } else if (control instanceof FormGroup) {
            doRemove(control);
          }
        });
      };
      doRemove(this.form!);
    }
  }

}
