import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ConstantsProviderService} from '../../resources/constants-provider.service';
import {IRowMenuItem, RowMenuButtonComponent} from '../../../shared/components/table/table-menus';
import {AbstractEmbeddableComponent} from '../../../shared/components/abstract-embeddable-component';
import {TooltipModule} from 'primeng/tooltip';
import {RouterLink} from '@angular/router';
import {TooltipOnOverflowDirective} from '../../../shared/components/tooltip-on-overflow.directive';
import {FormsModule} from '@angular/forms';
import {AvatarComponent} from '../../../shared/components/avatar.component';
import {FormControlWrapperComponent} from '../../../shared/components/entity-editor/form-control-wrapper.component';
import {DatePipe, NgClass} from '@angular/common';
import {TableCellComponent} from '../../../shared/components/table/table-cell.component';
import {ColumnHeaderComponent} from '../../../shared/components/table/column-header.component';
import {AbstractEntityTable} from '../../../shared/components/table/abstract-entity-table';

@Component({
  selector: 'app-fc-name',
  template: `
    <app-form-control-wrapper controlName="name"></app-form-control-wrapper>
  `,
  standalone: true,
  imports: [FormControlWrapperComponent]
})
export class CommonFormControlName {
}


/**
 * {@link IArchivable}
 */
@Component({
  selector: 'app-fc-isArchived',
  template: `
    <app-form-control-wrapper controlName="isArchived" controlType="dropdown" label="Archive Status"
                              [options]="constantsProvider.archivableStatus"></app-form-control-wrapper>
  `,
  standalone: true,
  imports: [FormControlWrapperComponent]
})
export class CommonFormControlIsArchived {
  constructor(public constantsProvider: ConstantsProviderService) {
  }
}

@Component({
  selector: 'app-th-isArchived',
  template: `
    <ng-template #template>
      <app-th field="isArchived" label="Archive Status" filterType="optionsEquals"
              [fixedWidth]="120"
              [options]="constantsProvider.archivableStatus">
      </app-th>
    </ng-template>
  `,
  styles: [':host { display: none; }'],
  standalone: true,
  imports: [ColumnHeaderComponent]
})
export class CommonColumnHeaderIsArchived extends AbstractEmbeddableComponent {
  constructor(public constantsProvider: ConstantsProviderService) {
    super();
  }
}

@Component({
  selector: 'app-td-isArchived',
  template: `
    <ng-template #template>
      <app-td field="isArchived" type="custom" [rowData]="rowData">
        {{ rowData.isArchived ? 'Archived' : 'Active' }}
      </app-td>
    </ng-template>
  `,
  styles: [':host { display: none; }'],
  standalone: true,
  imports: [TableCellComponent]
})
export class CommonTableCellIsArchived extends AbstractEmbeddableComponent {
  @Input() rowData?: any;
}

/**
 * {@link ITimestamped}
 */
@Component({
  selector: 'app-th-createdAt',
  template: `
    <ng-template #template>
      <app-th field="createdAt" filterType="date" [fixedWidth]="180"></app-th>
    </ng-template>
  `,
  styles: [':host { display: none; }'],
  standalone: true,
  imports: [ColumnHeaderComponent]
})
export class CommonColumnHeaderCreatedAt extends AbstractEmbeddableComponent {
}

@Component({
  selector: 'app-td-createdAt',
  template: `
    <ng-template #template>
      <app-td field="createdAt" [value]="rowData.createdAt | date: 'MM/dd/yy hh:mm:ss a'"></app-td>
    </ng-template>
  `,
  styles: [':host { display: none; }'],
  standalone: true,
  imports: [TableCellComponent, DatePipe]
})
export class CommonTableCellCreatedAt extends AbstractEmbeddableComponent {
  @Input() rowData?: any;

}


@Component({
  selector: 'app-th-updatedAt',
  template: `
    <ng-template #template>
      <app-th field="updatedAt" filterType="date" [fixedWidth]="180"></app-th>
    </ng-template>
  `,
  styles: [':host { display: none; }'],
  standalone: true,
  imports: [ColumnHeaderComponent]
})
export class CommonColumnHeaderUpdatedAt extends AbstractEmbeddableComponent {
}

@Component({
  selector: 'app-td-updatedAt',
  template: `
    <ng-template #template>
      <app-td field="updatedAt" [value]="rowData.createdAt | date: 'MM/dd/yy hh:mm:ss a'"></app-td>
    </ng-template>
  `,
  styles: [':host { display: none; }'],
  standalone: true,
  imports: [TableCellComponent, DatePipe]
})
export class CommonTableCellUpdatedAt extends AbstractEmbeddableComponent {
  @Input() rowData?: any;
}


/**
 * Name
 */

@Component({
  selector: 'app-td-name',
  template: `
    <ng-template #template>
      <app-td field="name" type="custom" [rowData]="rowData" [tdStyle]="tdStyle"
              cellStyleClass="justify-content-between py-0">
        <div class="flex align-items-center overflow-x-hidden">
          @if (showAvatar) {
            <app-avatar [ngModel]="rowData.avatar" [name]="rowData.name" [editable]="false" class="mr-2"></app-avatar>
          } @else if (imageSrc !== undefined) {
            <img class="mr-2" [src]="imageSrc" height="16" width="16"/>
          }
          <div class="overflow-x-hidden">
            <div class="mt-overflow-ellipsis mt-link" appTooltipOnOverflow (click)="onClick.emit($event)">
              @if (!!getRouterLink()) {
                <a [routerLink]="getRouterLink()">{{ rowData.name }}</a>
              } @else {
                {{ rowData.name }}
              }
            </div>
            @if (auxData !== undefined) {
              <div class="text-xs text-gray-700 mt-overflow-ellipsis" appTooltipOnOverflow>
                {{ auxData }}
              </div>
            }
          </div>
        </div>
        <div class="flex align-items-center">
          @if (showDraft) {
            <i class="pi pi-file" [style.visibility]="!!rowData.isDraft ? 'visible' : 'hidden'"
               [pTooltip]="rowData.isDraft ? 'Draft' : undefined" tooltipPosition="bottom"></i>
          }
          @if (icon) {
            <i class="mr-1" [ngClass]="icon" [pTooltip]="iconTooltip" tooltipPosition="bottom" (click)="onIconClick.emit()"></i>
          }
          <app-row-menu-button [rowData]="rowData" [customMenuItems]="customMenuItems" [omitMenuItems]="omitMenuItems">
          </app-row-menu-button>
        </div>
      </app-td>
    </ng-template>
  `,
  styles: [':host { display: none; }'],
  standalone: true,
  imports: [
    TableCellComponent, AvatarComponent, FormsModule, TooltipOnOverflowDirective, RouterLink, TooltipModule,
    RowMenuButtonComponent, NgClass
  ]
})
export class CommonTableCellName extends AbstractEmbeddableComponent {
  @Input() rowData?: any;
  @Input() auxData?: any;
  @Input() showAvatar = false;
  @Input() imageSrc?: string | null;
  @Input() showDraft = false;
  @Input() omitMenuItems: Array<string> = [];
  @Input() customMenuItems?: Array<IRowMenuItem<any>>;
  @Input() routerLink?: any;
  @Input() icon?: string;
  @Input() iconTooltip?: string;
  @Input() tdStyle: { [prop: string]: any } = {};
  @Output() onClick = new EventEmitter<any>();
  @Output() onIconClick = new EventEmitter<any>();

  constructor(public entityTable: AbstractEntityTable<any>) {
    super();
  }

  getRouterLink(): any {
    if (!this.routerLink) {
      return undefined;
    }
    if (this.routerLink === true && this.entityTable) {
      return [this.entityTable.editUrl, {id: this.rowData.id}]
    }
    return this.routerLink;
  }
}

