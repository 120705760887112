import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Injectable,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  ViewContainerRef
} from '@angular/core';
import {Tooltip} from 'primeng/tooltip';
import {PrimeNGConfig} from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class TooltipOnOverflowShared {
  measuringEl!: HTMLElement;

  getMeasuringEl(): HTMLElement {
    if (!this.measuringEl) {
      this.measuringEl = document.createElement('span');
      this.measuringEl.style.position = 'fixed';
      this.measuringEl.style.top = '-9999px';
      document.body.appendChild(this.measuringEl);
    }
    return this.measuringEl;
  }
}

@Directive({
    selector: '[appTooltipOnOverflow]',
    standalone: true
})
export class TooltipOnOverflowDirective implements OnInit, AfterViewInit, OnDestroy {
  @Input() showTooltip = true;
  @Input() useParent = false;
  @Input() tipPosition: 'top' | 'bottom' |  'right' | 'left' = 'top';
  private tooltip: Tooltip;

  constructor(
    public el: ElementRef,
    public zone: NgZone,
    private shared: TooltipOnOverflowShared,
    primengConfig: PrimeNGConfig,
    viewContainer: ViewContainerRef,
    renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.tooltip = new Tooltip(platformId, el, zone, primengConfig, renderer, viewContainer);
  }

  ngOnInit(): void {
    this.tooltip.setOption({tooltipPosition: this.tipPosition});
    this.tooltip.setOption({tooltipStyleClass: 'mt-tooltip-on-overflow'});
  }

  ngAfterViewInit(): void {
    this.tooltip.ngAfterViewInit();
  }

  ngOnDestroy(): void {
    this.tooltip.ngOnDestroy();
  }

  needShow(): boolean {
    const el = this.el.nativeElement;
    const me = this.shared.getMeasuringEl();
    const cs = window.getComputedStyle(el, null);
    me.style.fontSize = cs.getPropertyValue('font-size');
    me.style.fontFamily = cs.getPropertyValue('font-family');
    me.style.fontWeight = cs.getPropertyValue('font-weight');
    me.innerText = el.innerText;
    const boundsEl = this.useParent ? this.el.nativeElement.parentElement : el;
    let subtract = 0;

    function parseVal(v: string): number {
      return Math.ceil(parseFloat(v));
    }

    if (this.useParent) {
      Array.from(this.el.nativeElement.parentElement.children as HTMLElement[])
        .forEach((ch: HTMLElement) => {
          const chCs = window.getComputedStyle(ch, null);
          const margins = parseVal(chCs.getPropertyValue('margin-left'))
            + parseVal(chCs.getPropertyValue('margin-right'));
          subtract += margins;
          if (ch !== this.el.nativeElement) {
            subtract += ch.getBoundingClientRect().width;
          } else {
            subtract += parseVal(chCs.getPropertyValue('padding-right'))
              + parseVal(chCs.getPropertyValue('padding-left'));
          }
        });
    }
    const boundCs = window.getComputedStyle(boundsEl, null);
    const pr = parseVal(boundCs.getPropertyValue('padding-right'));
    const pl = parseVal(boundCs.getPropertyValue('padding-left'));
    return (boundsEl.getBoundingClientRect().width - pr - pl - subtract) < me.getBoundingClientRect().width;
  }

  @HostListener('mouseenter', ['$event']) onMouseEnter($event: MouseEvent): void {
    if (this.showTooltip) {
      this.tooltip.setOption({disabled: !this.needShow()});
      this.tooltip.setOption({tooltipLabel: this.el.nativeElement.innerText});
    }
  }
}
