import {
  AbstractFieldFormControl,
  FormControlWrapperComponent
} from '../../../../shared/components/entity-editor/form-control-wrapper.component';
import {ISystemRole, IToken, TSystemRoleSave, TTokenSave} from '../../../../api/shared/app-domain/dictionaries';
import {Component, inject} from '@angular/core';
import {copyToClipboard, inheritanceProvider} from '../../../../shared/util/util';
import {AbstractEntityEditor} from '../../../../shared/components/entity-editor/abstract-entity-editor';
import {AbstractEntityEditorBase} from '../../../../shared/components/entity-editor/abstract-entity-editor-base';
import {SystemRolesResourceService} from '../../../resources/dictionaries/system-roles-resource.service';
import {
  AbstractControl,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {TControlsOf} from '../../../../shared/types';
import {IMenuItem, IRowMenuItem, MENU_SEPARATOR} from '../../../../shared/components/table/table-menus';
import {existsAsyncValidator} from '../../../../shared/util/validators';
import {TableCellComponent} from '../../../../shared/components/table/table-cell.component';
import {ColumnHeaderComponent} from '../../../../shared/components/table/column-header.component';
import {EntityTableComponent} from '../../../../shared/components/table/entity-table.component';
import {MenuItem, SharedModule} from 'primeng/api';
import {EntityEditDialogComponent} from '../../../../shared/components/entity-editor/entity-edit.dialog';
import {CommonPageComponent} from '../../../../shared/components/page/common-page.component';
import {SpinnerizerDirective} from '../../../../shared/components/spinnerizer.component';
import {OptionsPipe} from '../../../../shared/components/options.pipe';
import {AsyncPipe, NgIf} from '@angular/common';
import {CommonFormControlName, CommonTableCellName} from '../../common/common-controls-wrappers';
import {TokensResourceService} from '../../../resources/dictionaries/tokens-resource.service';
import {Route} from '@angular/router';
import {notifyCommonPageTitle} from '../../../../shared/components/page/common-page.service';


@Component({
  selector: 'app-system-role-form-control',
  template: `
    @switch (field) {
      @case ('name') {
        <app-fc-name/>
      }
      @case ('description') {
        <app-form-control-wrapper dataType="text"></app-form-control-wrapper>
      }
      @case ('permissions') {
        <app-form-control-wrapper controlType="multiselect" onDemandOptions="permissions"
                                  [optionsFilter]="true"></app-form-control-wrapper>
      }
    }
  `,
  providers: [
    inheritanceProvider(AbstractFieldFormControl, SystemRoleFormControlComponent)
  ],
  standalone: true,
  imports: [
    CommonFormControlName,
    FormControlWrapperComponent,
    AsyncPipe,
    OptionsPipe,
  ],
})
export class SystemRoleFormControlComponent extends AbstractFieldFormControl<TSystemRoleSave> {
}

@Component({
  selector: 'app-system-role-editor',
  template: `
    <div [spinnerizer]="loading">
      <form *ngIf="form" #frm [formGroup]="form">
        <div class="formgrid grid">
          <div class="field col-12 p-fluid">
            <app-system-role-form-control field="name"></app-system-role-form-control>
          </div>
          <div class="field col-12 p-fluid">
            <app-system-role-form-control field="description"></app-system-role-form-control>
          </div>
          <div class="field col-12 p-fluid">
            <app-system-role-form-control field="permissions"></app-system-role-form-control>
          </div>
        </div>
      </form>
    </div>
  `,
  providers: [
    inheritanceProvider(AbstractEntityEditorBase, SystemRoleEditorComponent)
  ],
  standalone: true,
  imports: [
    NgIf, FormsModule, ReactiveFormsModule, SystemRoleFormControlComponent,
    SpinnerizerDirective
  ]
})
export class SystemRoleEditorComponent extends AbstractEntityEditor<TSystemRoleSave, ISystemRole> {

  constructor(public systemRolesResource: SystemRolesResourceService) {
    super(systemRolesResource, 'System Role');
  }

  override buildForm(): void {
    const checkExistsName = this.getCheckExists(this.systemRolesResource.searchEntities.bind(this.systemRolesResource), 'name');
    const adminRoleValidator: ValidatorFn = (permissionsControl: AbstractControl): ValidationErrors | null => {
      if (!permissionsControl.value) {
        return null; // responsibility of 'required' validator
      }
      if (this.systemRolesResource.isAdminRole(this.entity!) && !permissionsControl.value.includes(this.systemRolesResource.getAllPermission())) {
        return {'The Admin role must include ALL permission': true};
      }
      return null;
    };
    this.form = this.fb.group<TControlsOf<TSystemRoleSave>>({
      name: new FormControl(this.entity!.name, {
        nonNullable: true,
        validators: [Validators.required],
        asyncValidators: [existsAsyncValidator(checkExistsName)]
      }),
      description: new FormControl(this.entity!.description, []),
      permissions: new FormControl(this.entity!.permissions, [Validators.required, adminRoleValidator]),
    });
    if (!this.isNew() && !this.param.duplicate && this.systemRolesResource.isAdminRole(this.entity!)) {
      this.setEnable('name', false);
    }
  }
}


@Component({
  selector: 'app-system-roles-table',
  template: `
    <app-entity-edit-dialog #editDialog width="600px" minWidth="600px">
      <ng-template pTemplate let-param>
        <app-system-role-editor [param]="param"></app-system-role-editor>
      </ng-template>
    </app-entity-edit-dialog>
    <app-entity-table #entityTable
                      [api]="systemRolesResource" [defaultSort]="[{field: 'name'}]"
                      [pageable]="false"
                      [omitMenuItems]="['edit', 'archive']" [showActions]="false"
                      [customMenuItems]="$any(customMenuItems)"
                      [editDialog]="editDialog" stateKey="systemRoles.roles.table">
      <ng-template pTemplate="$header">
        <app-th field="name" filterType="text"></app-th>
        <app-th field="description" filterType="text"></app-th>
        <app-th field="permissions" filterType="optionsArray" onDemandOptions="permissions">
        </app-th>
        <app-th field="userCount" filterType="numeric" [fixedWidth]="120"></app-th>
        <app-th field="tokenCount" filterType="numeric" [fixedWidth]="120"></app-th>
      </ng-template>
      <ng-template pTemplate="$body" let-rowData>
        <app-td-name [rowData]="rowData" [omitMenuItems]="['archive']" [tdStyle]="{maxWidth: '200px'}"
                     [customMenuItems]="$any(customMenuItems)"
                     (onClick)="entityTable.openCreateOrUpdate(rowData)"
                     [icon]="this.systemRolesResource.isAdminRole(rowData) ? 'pi pi-star' : undefined"
                     iconTooltip="Admin Role"/>
        <app-td field="description" [rowData]="rowData" [tdStyle]="{maxWidth: '200px'}"></app-td>
        <app-td field="permissions" type="stringArray" [rowData]="rowData" [tdStyle]="{maxWidth: '300px'}"></app-td>
        <app-td field="userCount" [rowData]="rowData" align="right"></app-td>
        <app-td field="tokenCount" [rowData]="rowData" align="right"></app-td>
      </ng-template>
    </app-entity-table>
  `,
  standalone: true,
  imports: [
    EntityEditDialogComponent, SharedModule, SystemRoleEditorComponent, EntityTableComponent, ColumnHeaderComponent,
    CommonTableCellName, TableCellComponent, AsyncPipe, OptionsPipe
  ]
})
export class SystemRolesTableComponent {
  customMenuItems: Array<IMenuItem<ISystemRole>> = [
    {
      id: 'delete', // override
      isEnabled: (param) => !this.systemRolesResource.isAdminRole(param.data)
    }
  ];

  constructor(public systemRolesResource: SystemRolesResourceService) {
    notifyCommonPageTitle('System Role', 'systemRolesCount');
  }
}

@Component({
  selector: 'app-token-form-control',
  template: `
    @switch (field) {
      @case ('name') {
        <app-fc-name/>
      }
      @case ('description') {
        <app-form-control-wrapper dataType="text"></app-form-control-wrapper>
      }
      @case ('systemRoles') {
        <app-form-control-wrapper controlType="multiselect" onDemandOptions="systemRoles"
                                  [optionsFilter]="true"></app-form-control-wrapper>
      }
    }
  `,
  providers: [
    inheritanceProvider(AbstractFieldFormControl, TokenFormControlComponent)
  ],
  standalone: true,
  imports: [
    CommonFormControlName, FormControlWrapperComponent, AsyncPipe, OptionsPipe,
  ],
})
export class TokenFormControlComponent extends AbstractFieldFormControl<TTokenSave> {
}

@Component({
  selector: 'app-token-editor',
  template: `
    <div [spinnerizer]="loading">
      <form *ngIf="form" [formGroup]="form">
        <div class="formgrid grid">
          <div class="field col-12 p-fluid">
            <app-token-form-control field="name"></app-token-form-control>
          </div>
          <div class="field col-12 p-fluid">
            <app-token-form-control field="description"></app-token-form-control>
          </div>
          <div class="field col-12 p-fluid">
            <app-token-form-control field="systemRoles"></app-token-form-control>
          </div>
        </div>
      </form>
    </div>
  `,
  providers: [
    inheritanceProvider(AbstractEntityEditorBase, TokenEditorComponent)
  ],
  standalone: true,
  imports: [
    NgIf, FormsModule, ReactiveFormsModule, SpinnerizerDirective, TokenFormControlComponent
  ]
})
export class TokenEditorComponent extends AbstractEntityEditor<TTokenSave, IToken> {
  constructor(public tokensResource: TokensResourceService) {
    super(tokensResource, 'Token');
  }

  override buildForm(): void {
    const checkExistsName = this.getCheckExists(this.tokensResource.searchEntities.bind(this.tokensResource), 'name');
    this.form = this.fb.group<TControlsOf<TTokenSave>>({
      name: new FormControl(this.entity!.name, {
        nonNullable: true, validators: [Validators.required], asyncValidators: [existsAsyncValidator(checkExistsName)]
      }),
      description: new FormControl(this.entity!.description, []),
      systemRoles: new FormControl(this.entity!.systemRoles, [Validators.required]),
    });
  }
}

@Component({
  selector: 'app-tokens-table',
  template: `
    <app-entity-edit-dialog #editDialog width="600px" minWidth="600px">
      <ng-template pTemplate let-param>
        <app-token-editor [param]="param"></app-token-editor>
      </ng-template>
    </app-entity-edit-dialog>
    <app-entity-table #entityTable
                      [api]="tokensResource" [defaultSort]="[{field: 'name'}]"
                      [omitMenuItems]="['edit', 'archive']" [showActions]="false"
                      [editDialog]="editDialog" stateKey="systemRoles.tokens.table">
      <ng-template pTemplate="$header">
        <app-th field="name" filterType="text"></app-th>
        <app-th field="description" filterType="text"></app-th>
        <app-th field="systemRoles" filterType="optionsArray" onDemandOptions="systemRoles">
        </app-th>
        <app-th field="permissions" filterType="optionsArray" onDemandOptions="permissions">
        </app-th>
      </ng-template>
      <ng-template pTemplate="$body" let-rowData>
        <app-td-name [rowData]="rowData" [omitMenuItems]="['archive']" [tdStyle]="{maxWidth: '200px'}"
                     [customMenuItems]="$any(customMenuItems)"
                     (onClick)="entityTable.openCreateOrUpdate(rowData)"
                     icon="pi pi-copy cursor-pointer" (onIconClick)="copyTokenToClipboard(rowData)"
                     iconTooltip="Copy Token To Clipboard"/>
        <app-td field="description" [rowData]="rowData" [tdStyle]="{maxWidth: '200px'}"></app-td>
        <app-td field="systemRoles" type="stringArray" [rowData]="rowData" [tdStyle]="{maxWidth: '300px'}"></app-td>
        <app-td field="permissions" type="stringArray" [rowData]="rowData" [tdStyle]="{maxWidth: '300px'}"></app-td>
      </ng-template>
    </app-entity-table>
  `,
  standalone: true,
  imports: [
    EntityEditDialogComponent, SharedModule, EntityTableComponent, ColumnHeaderComponent,
    CommonTableCellName, TableCellComponent, AsyncPipe, OptionsPipe, TokenEditorComponent
  ]
})
export class TokensTableComponent {
  tokensResource = inject(TokensResourceService);
  customMenuItems: Array<IRowMenuItem<IToken>> = [
    {
      id: 'copyTokenToClipboard',
      icon: 'pi pi-copy',
      isEnabled: (param) => true,
      onCommand: (param) => this.copyTokenToClipboard(param.data)
    },
    MENU_SEPARATOR
  ];

  constructor() {
    notifyCommonPageTitle('System Token', 'tokensCount');
  }

  copyTokenToClipboard(rowData: IToken): void {
    copyToClipboard(rowData.payload)
  }
}


@Component({
  selector: 'app-system-role-page',
  template: `
    <app-common-page [navMenu]="navMenu" stateKey="systemRoles">
    </app-common-page>
  `,
  standalone: true,
  imports: [CommonPageComponent],
})
export class SystemRolePageComponent {
  navMenu: Array<MenuItem> = [
    {
      label: 'Roles', routerLink: ['roles'],
    },
    {
      label: 'Tokens', routerLink: ['tokens'],
    }
  ];
}


export default [
  {path: '', redirectTo: 'roles', pathMatch: 'full'},
  {path: 'roles', component: SystemRolesTableComponent, title: 'Roles'},
  {path: 'tokens', component: TokensTableComponent, title: 'Tokens'},
] satisfies Array<Route>;
