import {Directive, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {debounceTime, distinctUntilChanged, Subscription} from 'rxjs';
import {NgControl} from '@angular/forms';

@Directive({
    selector: '[ngModel][appChangesDebounce]',
    standalone: true
})
export class ChangesDebounceDirective implements OnInit, OnDestroy {
  @Input() appChangesDebounce!: string;

  @Output()
  debounced = new EventEmitter<any>();

  private isFirstChange = true;
  private subscription!: Subscription;

  constructor(public control: NgControl) {
  }

  ngOnInit(): void {
    if (!this.appChangesDebounce) {
      this.appChangesDebounce = '300';
    }
    this.subscription =
      this.control.valueChanges!.pipe(
        debounceTime(+this.appChangesDebounce),
        distinctUntilChanged()
      ).subscribe((modelValue) => {
        if (this.isFirstChange) {
          this.isFirstChange = false;
        } else {
          this.debounced.emit(modelValue);
        }
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
