import {Directive} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';

@Directive()
export abstract class AbstractEntityEditorBase<T, F extends { [K in keyof F]: AbstractControl<any>; } = any> {
  form?: FormGroup<F> | null;
  protected _loading = false;
  protected _title!: string;
  protected _applyAttempted = false;

  get applyAttempted(): boolean {
    return this._applyAttempted
  }

  get title(): string {
    return this._title;
  }

  get loading(): boolean {
    return this._loading;
  }

  isValid(): boolean {
    return !!this.form?.valid;
  }

  isDirty(): boolean {
    return !!this.form?.dirty;
  }

  isDataChanged(): boolean {
    return this.isDirty();
  }

  canSubmit(): boolean {
    if (!this.form) {
      return false;
    }
    if (!this.applyAttempted) {
      return this.isDirty();
    } else {
      return this.isValid();
    }
  }

  canPerformApply(): boolean {
    if (!this.form) {
      return false;
    }
    this.touchAllFormFields(this.form);
    this._applyAttempted = true;
    return this.isValid() && this.isDirty();
  }


  getValue(field: keyof F): any {
    if (this.form?.controls == null) {
      return undefined;
    }
    return (this.form?.controls as F)[field].value;
  }

  getControl(field: keyof F): AbstractControl | undefined {
    return (this.form?.controls as F)[field];
  }

  protected getData(): T {
    return this.form!.getRawValue() as T;
  }

  setValueAndMark(field: keyof F, value: any): void {
    (this.form!.controls as F)[field].setValue(value);
    (this.form!.controls as F)[field].markAsDirty();
    (this.form!.controls as F)[field].markAsTouched();
  }

  assignValue(field: keyof F, value: any): void {
     (this.form!.controls as F)[field].setValue(value, {onlySelf: true, emitEvent: false});
  }

  setEnable(field: keyof F, isEnable: boolean, setRequired = true): void {
    if (this.form) {
      if (isEnable) {
        (this.form.controls as F)[field].enable();
        if (setRequired) {
          (this.form.controls as F)[field].setValidators([Validators.required]);
        }
      } else {
        (this.form.controls as F)[field].disable();
        if (setRequired) {
          (this.form.controls as F)[field].removeValidators([Validators.required]);
        }
      }
      if (setRequired) {
        (this.form.controls as F)[field].updateValueAndValidity();
      }
    }
  }

  abstract apply(onApply: (result: T) => void): void;

  touchAllFormFields(formGroup: FormGroup, untouch = false): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        if (untouch) {
          control.markAsUntouched({onlySelf: true});
        } else {
          control.markAsTouched({onlySelf: true});
        }
      } else if (control instanceof FormGroup) {
        this.touchAllFormFields(control);
      }
    });
  }


}
