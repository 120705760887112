import {Observable, of, switchMap, timer} from 'rxjs';
import {AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn} from '@angular/forms';
import {catchError, map} from 'rxjs/operators';

export const urlValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  let isValid = true;
  try {
    if (control.value) {
      const url = new URL(control.value);
      if (!url.host || !url.origin || !url.protocol) {
        isValid = false;
      }
    }
  } catch {
    isValid = false;
  }
  return isValid ? null : {invalidUrl: true};
};

export function existsAsyncValidator(checkFn: (value: any) => Observable<boolean>, delay = 300): AsyncValidatorFn {
  return (control: AbstractControl) => {
    return timer(delay).pipe(
      switchMap(() => {
        return checkFn(control.value)
          .pipe(
            map((response) => {
              return response ? {exists: true} : null;
            }),
            catchError(() => of(null)) // TODO: error?
          );
      })
    )
  }
}
