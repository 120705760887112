import {Injectable} from '@angular/core';
import {Endpoint, EntityResourceBaseService} from '../../../shared/services/resources/entity-resource-base.service';
import {IToken, TTokenSave} from '../../../api/shared/app-domain/dictionaries';

@Injectable({
  providedIn: 'root',
})
@Endpoint('/api/settings/tokens')
export class TokensResourceService extends EntityResourceBaseService<TTokenSave, IToken> {
}
