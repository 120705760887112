import {AfterViewInit, Component, Input, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import {AbstractControlValueAccessor} from './abstract-control-value-accessor';
import {TreeNode} from 'primeng/api';
import {valueAccessorProvider} from '../util/util';
import {FormsModule} from '@angular/forms';
import {TreeSelectModule} from 'primeng/treeselect';

@Component({
  selector: 'app-tree-select',
  template: `
    <ng-template #template>
      <p-treeSelect
        [filter]="filter"
        selectionMode="single"
        [(ngModel)]="model"
        [options]="options!"
        [placeholder]="placeholder"
        (ngModelChange)="onChange($event)"
        appendTo="body"
        [showClear]="showClear" [disabled]="isDisabled" [containerStyle]="style!">
      </p-treeSelect>
    </ng-template>
  `,
  providers: [valueAccessorProvider(TreeSelectComponent)],
  standalone: true,
  imports: [TreeSelectModule, FormsModule]
})
export class TreeSelectComponent extends AbstractControlValueAccessor<any> implements AfterViewInit {
  @Input() options!: Array<TreeNode>;
  @Input() placeholder!: string;
  @Input() showClear = false;
  @Input() filter = false;
  @Input() style?: object;
  @ViewChild('template', {static: true}) template!: TemplateRef<any>;

  model?: TreeNode;

  constructor(protected viewContainerRef: ViewContainerRef) {
    super();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.viewContainerRef.createEmbeddedView(this.template);
    });
  }

  onChange(node: TreeNode): void {
    this.value = node == null ? null : (node.data != null ? node.data : node.label);
    this.onModelChange(this.value);
  }

  override writeValue(value: any) {
    this.model = this.findTreeNode(value, this.options) || undefined;
    super.writeValue(value);
  }

  findTreeNode(value: string, nodes: Array<TreeNode>): TreeNode | null {
    if (value == null || nodes == null) {
      return null;
    }
    let foundNode: TreeNode | null = null;
    for (const node of nodes) {
      if (node.selectable !== false && ((node.data != null && value === node.data) || value === node.label)) {
        foundNode = node;
        break;
      }
      if (node.children) {
        foundNode = this.findTreeNode(value, node.children);
        if (foundNode) {
          break;
        }
      }
    }
    return foundNode;
  }
}
