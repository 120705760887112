import {Injectable} from '@angular/core';
import {Endpoint, EntityResourceBaseService} from '../../../shared/services/resources/entity-resource-base.service';
import {ISystemRole, TSystemRoleSave} from '../../../api/shared/app-domain/dictionaries';
import {OnDemandLoader} from '../../../shared/services/resources/on-demand-resource-loader.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
@Endpoint('/api/settings/system-roles')
export class SystemRolesResourceService extends EntityResourceBaseService<TSystemRoleSave, ISystemRole> {

  @OnDemandLoader('permissions')
  permissions(): Observable<Array<string>> {
    return this.http.get<Array<string>>(this.url('permissions'));
  }

  @OnDemandLoader('systemRoles')
  systemRoles(): Observable<Array<string>> {
    return this.stringArrayFetchHelper('name');
  }

  isAdminRole(entity: ISystemRole): boolean {
    return entity.name === 'TS_ADMIN';
  }

  getAllPermission(): string {
    return 'ALL';
  }
}

