import {Directive, inject, OnInit, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';

@Directive()
export abstract class AbstractEmbeddableComponent implements OnInit {
  viewContainerRef = inject(ViewContainerRef);
  @ViewChild('template', {static: true}) template!: TemplateRef<any>;

  ngOnInit(): void {
    this.viewContainerRef.createEmbeddedView(this.template);
    this.viewContainerRef.element.nativeElement.remove();
  }
}
