import {MenuItem} from 'primeng/api';
import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {assign, find, remove, some, startCase, unionWith} from 'lodash';
import {FileUploadDialog, FileUploadDialog as FileUploadDialog_1} from '../file';
import {IArchivable, IIdentified} from '../../../api/shared/common';
import {AbstractEntityTable} from './abstract-entity-table';
import {NgIf} from '@angular/common';
import {TooltipModule} from 'primeng/tooltip';
import {MenubarModule} from 'primeng/menubar';
import {ChangesDebounceDirective} from '../changes-debounce.directive';
import {InputTextModule} from 'primeng/inputtext';
import {FormsModule} from '@angular/forms';
import {RippleModule} from 'primeng/ripple';
import {ButtonModule} from 'primeng/button';
import {MenuModule} from 'primeng/menu';
import {TooltipOnOverflowDirective} from '../tooltip-on-overflow.directive';
import {TipComponent} from '../tip.component';

export interface IMenuItemCallbackParam<T> {
  item: MenuItem;
  data: T;
  entityTable: AbstractEntityTable<any>;
  target?: any;
}

export interface IMenuItem<T extends IIdentified> extends MenuItem {
  isEnabled?: (param: IMenuItemCallbackParam<T>) => boolean | null;  // null means hiding the item for row menu
}

export interface IRowMenuItem<T extends IIdentified> extends IMenuItem<T> {
  onCommand: (param: IMenuItemCallbackParam<T>) => void;
}

export const MENU_SEPARATOR = {separator: true} as any;

export interface ITableMenuItem<T extends IIdentified> extends IMenuItem<T> {
  onCommand: (param: IMenuItemCallbackParam<Array<T>>) => void;
}

@Component({
  selector: 'app-row-menu-button',
  template: `
    <div style="width: 30px; min-width: 30px">
      <p-menu #menu [popup]="true" [model]="rowMenuItems" appendTo="body" [style]="{width: 'auto', minWidth: '150px'}"
              (onShow)="onShowRowMenu()"></p-menu>
      <button #menuButton pButton pRipple type="button" icon="pi pi-ellipsis-v"
              class="p-button-rounded p-button-text p-button-sm w-2rem h-2rem"
              (click)="menu.toggle($event)">
      </button>
    </div>
  `,
  standalone: true,
  imports: [MenuModule, ButtonModule, RippleModule]
})
export class RowMenuButtonComponent {
  @Input() rowData!: any;
  @Input() omitMenuItems: Array<string> = [];
  @Input() customMenuItems?: Array<IRowMenuItem<any>>;
  @ViewChild('menuButton') menuButton!: ElementRef;

  constructor(public entityTable: AbstractEntityTable<any>) {
  }


  defaultRowMenuItems: Array<IRowMenuItem<IIdentified>> = [
    {
      id: 'edit',
      icon: 'pi pi-pencil',
      onCommand: (param) => this.entityTable.openCreateOrUpdate(param.data)
    },
    {
      id: 'duplicate',
      icon: 'pi pi-clone',
      onCommand: (param) => this.entityTable.openDuplicate(param.data.id)
    },
    {
      id: 'archive',
      icon: 'pi pi-server',
      isEnabled: (param) => !(param.data as unknown as IArchivable).isArchived,
      onCommand: (param) => this.entityTable.archiveEntities([param.data])
    },
    {
      id: 'delete',
      icon: 'pi pi-trash',
      styleClass: 'mt-alert-menuitem',
      onCommand: (param) => this.entityTable.deleteEntities(param.target, [param.data])
    }
  ];

  rowMenuItems: Array<IRowMenuItem<IIdentified>> = [];

  onShowRowMenu(): void {
    const standardRowMenuItems = [...this.defaultRowMenuItems];
    const customMenuItems = [...this.customMenuItems || []];
    remove(standardRowMenuItems, (mi) => this.omitMenuItems.includes(mi.id!));
    const defaultMenuIds = this.defaultRowMenuItems.map((mi) => mi.id!);
    const overriddenMenuItems = remove(customMenuItems, (mi) => defaultMenuIds.includes(mi.id!));
    standardRowMenuItems.forEach((mi) => assign(mi, find(overriddenMenuItems, {id: mi.id}) ?? {}));

    this.rowMenuItems = unionWith(customMenuItems ?? [], standardRowMenuItems, (a, b) => a.id === b.id);
    this.rowMenuItems.forEach((mi) => {
      if (!mi.separator) {
        if (mi.label == null) {
          mi.label = startCase(mi.id);
        }
        const param: IMenuItemCallbackParam<any> = {
          item: mi,
          data: this.rowData,
          target: this.menuButton.nativeElement,
          entityTable: this.entityTable
        }
        mi.command = () => mi.onCommand(param)
        if (mi.isEnabled) {
          const isEnabled = mi.isEnabled!(param);
          if (isEnabled == null) {
            mi.visible = false;
          } else {
            mi.visible = true;
            mi.disabled = !isEnabled;
          }
        }
      }
    });
  }

}

@Component({
  selector: 'app-table-toolbar',
  template: `
    <app-file-upload-dialog #fileUploadDialog></app-file-upload-dialog>
    <div class="flex align-items-center justify-content-between mb-2">
      <div class="flex align-items-center">
        <span class="p-input-icon-left p-input-icon-right">
          <i class="pi pi-search"></i>
          <i class="pi pi-times cursor-pointer" (click)="searchModel.control.setValue(null)"></i>
          <input #searchModel="ngModel" [(ngModel)]="search"
                 pInputText type="text" class="p-inputtext-sm" [style.width]="'300px'"
                 placeholder="Global Search"
                 appChangesDebounce (debounced)="entityTable.performSearch($event)"/>
        </span>
        <div class="ml-3 text-gray-700" style="width: 6rem;">{{ entityTable.selection.length }} selected</div>
        <p-menubar class="ml-2 mt-page-menu-bar" [model]="tableMenu"></p-menubar>
      </div>
      <div class="flex align-items-center">
        <div *ngIf="entityTable.hasFilters()" class="mr-4 flex align-items-center font-medium" style="max-width: 30vw">
          <img src="assets/images/tip.png" width="16" class="mr-1"/>
          <div class="white-space-nowrap">Filtered by:</div>
          <div class="ml-2 text-gray-600 mt-overflow-ellipsis" appTooltipOnOverflow>
            {{ entityTable.getFilteredByAsString() }}
          </div>
        </div>
        <button pButton pRipple type="button"
                icon="pi pi-filter-slash" class="p-button-rounded p-button-text"
                pTooltip="Clear Filters" tooltipPosition="bottom" [disabled]="!entityTable.hasFilters()"
                [class.mt-emphasis-bg]="entityTable.hasFilters()"
                (click)="entityTable.clearFilters()"></button>
        <button pButton pRipple type="button"
                icon="pi pi-refresh" class="p-button-rounded p-button-text"
                pTooltip="Reload" tooltipPosition="bottom" (click)="entityTable.reload()"></button>
        <button pButton pRipple type="button"
                icon="pi pi-plus" class="p-button-rounded p-button-text"
                pTooltip="New Entry" tooltipPosition="bottom" (click)="entityTable.openCreateOrUpdate(null)"></button>
        <ng-container *ngIf="showActions">
          <p-menu #tableActionsMenu [popup]="true" [model]="tableActionItems"></p-menu>
          <button pButton pRipple label="Table Actions" class="ml-2 p-button-sm p-button-outlined"
                  icon="pi pi-chevron-down"
                  iconPos="right" (click)="tableActionsMenu.toggle($event)"></button>
        </ng-container>
      </div>
    </div>
  `,
  styles: [
    `
      :host ::ng-deep .p-menubar .p-menuitem-separator {
        border-left: 1px solid rgba(0, 0, 0, 0.12);
        margin: 0 0.5rem;
        height: 2rem;
      }
    `
  ],
  standalone: true,
  imports: [
    FileUploadDialog_1, FormsModule, InputTextModule, ChangesDebounceDirective, MenubarModule, ButtonModule,
    RippleModule, TooltipModule, NgIf, MenuModule, TooltipOnOverflowDirective, TipComponent
  ]
})
export class TableToolbarComponent implements OnChanges, OnInit {
  @Input() omitMenuItems: Array<string> = [];
  @Input() customMenuItems?: Array<ITableMenuItem<any>>;
  @Input() showActions = true;
  @ViewChild(FileUploadDialog) fileUploadDialog!: FileUploadDialog;
  search?: string;

  tableActionItems: Array<MenuItem> = [
    {
      label: 'Import',
      icon: 'pi pi-fw pi-file-import',
      command: () => this.entityTable.importEntities(this.fileUploadDialog)
    },
    {
      label: 'Export to CSV',
      icon: 'pi pi-fw pi-file-export',
      command: () => this.entityTable.exportEntities()
    }
  ];
  tableMenu: Array<ITableMenuItem<IIdentified>> = [];

  defaultTableMenu: Array<ITableMenuItem<IIdentified>> = [
    {
      id: 'edit',
      icon: 'pi pi-pencil',
      onCommand: (param) => this.entityTable.openBulkEdit(param.data)
    },
    {
      id: 'archive',
      icon: 'pi pi-server',
      isEnabled: (param) => !(param.data as unknown as IArchivable).isArchived,
      onCommand: (param) => this.entityTable.archiveEntities(param.data)
    },
    {
      id: 'delete',
      icon: 'pi pi-trash',
      onCommand: (param) => this.entityTable.deleteEntities(param.target, param.data)
    },
    {style: {display: 'none'}} as any  // https://github.com/primefaces/primeng/issues/14434
  ];

  constructor(public entityTable: AbstractEntityTable<any>) {
    entityTable.onStateRestored.subscribe((state) => {
      this.search = state.search;
    });
    entityTable.onClearFilters.subscribe(() => this.search = undefined);
  }

  ngOnInit(): void {
    this.updateMenu();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const standardMenuItems = [...this.defaultTableMenu];
    const customMenuItems = [...this.customMenuItems || []];
    const defaultMenuIds = this.defaultTableMenu.map((mi) => mi.id!);
    const overriddenMenuItems = remove(customMenuItems, (mi) => mi.id && defaultMenuIds.includes(mi.id));
    standardMenuItems.forEach((mi) => assign(mi, find(overriddenMenuItems, {id: mi.id}) ?? {}));

    this.tableMenu = unionWith(customMenuItems, standardMenuItems, (a, b) => a.id != null && a.id === b.id);
    if (changes['omitMenuItems']) {
      remove(this.tableMenu, (mi) => this.omitMenuItems.includes(mi.id!));
    }
    this.updateMenu();
  }


  updateMenu(): void {
    this.tableMenu.forEach((mi) => {
      if (mi.id) {
        mi.disabled = !this.entityTable.selection?.length;
        if (mi.label == null) {
          mi.label = startCase(mi.id);
        }
        mi.command = (event) => mi.onCommand({
          item: mi,
          data: (this.entityTable.selection || []).filter((sel) => mi.isEnabled ? mi.isEnabled({
            item: mi,
            data: sel,
            entityTable: this.entityTable
          }) : true),
          entityTable: this.entityTable,
          target: event.originalEvent?.target
        });
      }
    });
    if (this.entityTable.selection?.length) {
      this.tableMenu.forEach((mi) => {
        if (mi.id && !!mi.isEnabled) {
          const someEnabled = some(this.entityTable.selection!, (rowData) => mi.isEnabled!({
            item: mi,
            data: rowData,
            entityTable: this.entityTable
          }));
          mi.disabled = !someEnabled;
        }
      });
    }
    this.tableMenu = [...this.tableMenu];
  }

}
