import {LazyLoadEvent, SortMeta, TableState} from 'primeng/api';
import {ISearchRequest, ISearchResponse, TQueryExpression} from '../../../api/shared/search-api';
import {finalize, Observable, of, Subscription} from 'rxjs';
import {
  AfterViewInit, ChangeDetectorRef,
  Directive,
  EventEmitter, Inject,
  inject, Injectable,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {IIdentified} from '../../../api/shared/common';
import {getStorageItem, onChangesAfterFirst} from '../../util/util';
import {Table, TableFilterEvent, TablePageEvent} from 'primeng/table';
import {filter, findIndex, isArray, isEmpty, isEqual, keys, omit, pick, remove} from 'lodash';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {EntityEditDialogComponent} from '../entity-editor/entity-edit.dialog';
import {IEntityResource} from '../../services/resources/entity-resource';
import {TableToolbarComponent} from './table-menus';
import {IEntityEditorParam} from '../entity-editor/abstract-entity-editor';
import {IEntityBulkEditorParam} from '../entity-editor/abstract-entity-bulk-editor';
import {FileUploadDialog} from '../file';
import {Alert} from '../../util/alert';
import {ActivatedRoute, Router} from '@angular/router';

import {stdConfirm} from '../confirm';
import {CommonPageService, NewEntityEvent} from '../page/common-page.service';
import {ColumnsInfoService} from './column-header.component';
import {EStateStrategy, StateAdapter} from '../../services/state-adapter';
import {lazyToSearchRequest} from './table-query';

export interface IResponseRequest<T> {
  request: ISearchRequest;
  response: ISearchResponse<T>;
}


export interface ITableState {
  search?: string;
  first?: number;
  rows?: number;
  multiSortMeta?: SortMeta[];
  filters?: {
    [s: string]: FilterMetadata | FilterMetadata[];
  };
  selection?: any;
}


@Directive()
export abstract class AbstractEntityTable<T extends IIdentified, R extends {} = any> implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() query?: TQueryExpression;
  @Input() requestParam?: R;
  @Input() editDialog?: EntityEditDialogComponent;
  @Input() bulkEditDialog?: EntityEditDialogComponent;
  @Input() defaultSort?: Array<{ field: keyof T; order?: -1 | 1 }>;
  @Input() api: Partial<IEntityResource<any, T>> = {};
  @Input() editUrl?: string;
  @Input() ignoreEditUrlFor: Array<'newEntity' | 'editMenu' | 'duplicateMenu'> | null = [
    'newEntity', 'editMenu', 'duplicateMenu'
  ];
  @Input() stateKey?: string;
  @Output() onDataFetched: EventEmitter<IResponseRequest<T>> = new EventEmitter<IResponseRequest<T>>();
  @Output() onClearFilters: EventEmitter<IResponseRequest<T>> = new EventEmitter<IResponseRequest<T>>();
  @Output() onStateRestored: EventEmitter<TableState & { search?: string }> = new EventEmitter<TableState & {
    search?: string
  }>();
  @ViewChild(Table) primeTable!: Table;
  @ViewChild(TableToolbarComponent) tableToolbar?: TableToolbarComponent;
  columnsInfo = inject(ColumnsInfoService, {optional: true});
  stateAdapter = new StateAdapter();

  protected _selection: Array<T> = [];
  get selection(): Array<T> {
    return this._selection;
  }

  set selection(selection: Array<T>) {
    this._selection = selection;
    this.saveState();
  }

  protected _filters: { [prop: string]: FilterMetadata | FilterMetadata[]; } = {};
  get filters() {
    return this._filters;
  }

  protected loading = false;
  protected data!: Array<T>;
  protected _search?: string;
  get search(): string | undefined {
    return this._search;
  }

  protected currentSearchRequest: ISearchRequest = {};
  protected sortMeta?: Array<SortMeta>;
  protected pageSize = 10;
  protected total = 0;
  protected firstRow = 0;
  protected subscriptions: Array<Subscription> = [];

  protected router = inject(Router);
  protected cdr = inject(ChangeDetectorRef);
  constructor() {
    const commonPageService = inject(CommonPageService, {optional: true});
    if (commonPageService) {
      this.subscriptions.push(
        commonPageService.eventsOfType(NewEntityEvent).subscribe(() => this.openCreateOrUpdate(null)),
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    onChangesAfterFirst<typeof this>(changes, ['query', 'requestParam'], () => this.load());
  }

  ngOnInit(): void {
    if (this.defaultSort) {
      this.sortMeta = this.defaultSort.map((s) => ({field: s.field as string, order: s.order || 1}));
    }
  }

  ngAfterViewInit(): void {
    this.restoreState();
    this.load();
  }

  ngOnDestroy(): void {
    while (this.subscriptions.length) {
      this.subscriptions.shift()!.unsubscribe();
    }
  }

  onSelectionChange(event: any): void {
    this.saveState();
    this.updateTableMenu();
  }

  onFilter(event: TableFilterEvent): void {
    this.saveState();
  }

  onSort(event: any): void {
    this.saveState();
  }

  onPage(event: TablePageEvent): void {
    this.saveState();
  }

  saveState(): void {
    if (!this.stateKey) {
      return;
    }
    setTimeout(() => {
      let state: ITableState = {};
      if (this.search) {
        state.search = this.search;
      }
      if (this.primeTable.paginator) {
        if (this.primeTable.first) {
          state.first = this.primeTable.first as number;
        }
        if (this.primeTable.rows !== 10) {
          state.rows = this.primeTable.rows;
        }
      }
      if (this.primeTable.multiSortMeta) {
        const defaultSort = (this.defaultSort || []).map((s) => ({...{order: 1}, ...s}));
        if (!isEqual(this.primeTable.multiSortMeta, defaultSort)) {
          state.multiSortMeta = this.primeTable.multiSortMeta;
        }
      }
      if (this.getFilteredByArray().length) {
        state.filters = this.primeTable.filters;
      }
      this.stateAdapter.saveState(this.stateKey!, state);

      state = {};
      if (this.selection && this.selection.length) {
        state.selection = this.selection;
      }
      this.stateAdapter.saveState(this.stateKey!, state, EStateStrategy.Storage);
    });
  }

  restoreState(): void {
    if (!this.stateKey) {
      return;
    }
    const urlState = this.stateAdapter.restoreState<ITableState>(this.stateKey);
    if (urlState) {
      this._filters = {...this.filters, ...urlState.filters || {}};
      if (urlState.multiSortMeta) {
        this.sortMeta = urlState.multiSortMeta;
      }
      if (urlState.search) {
        this._search = urlState.search;
      }
      if (urlState.first !== undefined) {
        this.firstRow = urlState.first;
      }
      if (urlState.rows !== undefined) {
        this.pageSize = urlState.rows;
      }
    }
    const storageState = this.stateAdapter.restoreState<ITableState>(this.stateKey, EStateStrategy.Storage);
    if (storageState) {
      if (storageState.selection) {
        this._selection = storageState.selection;
      }
    }
    if (urlState || storageState) {
      this.onStateRestored.emit({...urlState, ...storageState});
      this.cdr.detectChanges();
    }
  }


  useFetchAll(): void {
    this.pageSize = Number.MAX_SAFE_INTEGER;
  }

  load(event?: LazyLoadEvent): void {
    setTimeout(() => {
      this.loading = true;
    });
    const searchRequest: ISearchRequest = lazyToSearchRequest(
      event || {...this.primeTable.createLazyLoadMetadata(), first: this.firstRow}, this.pageSize, this.search);
    this.prepareSearchRequest(searchRequest);
    this.fetchData(
      searchRequest
    ).pipe(
      finalize(() => setTimeout(() => this.loading = false))
    ).subscribe((response) => {
      this.currentSearchRequest = searchRequest;
      this.trace('search response', response);
      this.data = response.results;
      this.onDataFetched.emit({request: searchRequest, response});
      this.total = response.total;
    });
  }

  reload(): void {
    this.firstRow = 0;
    this.selection = [];
    this.load();
  }

  updateTableMenu(): void {
    if (this.tableToolbar) {
      this.tableToolbar.updateMenu();
    }
  }

  performSearch(search: string): void {
    this._search = search;
    this.firstRow = 0;
    this.saveState();
    this.load();
  }

  protected prepareSearchRequest(searchRequest: ISearchRequest): ISearchRequest {
    if (searchRequest.query || this.query) {
      searchRequest.query = {
        logical: 'and',
        predicates: [
          ...(this.query?.predicates || []),
          ...((searchRequest.query as TQueryExpression)?.predicates || [])
        ]
      }
    }
    if (this.requestParam) {
      searchRequest.param = this.requestParam;
    }
    this.trace('search request', searchRequest);
    return searchRequest;
  }

  protected fetchData(searchRequest: ISearchRequest): Observable<ISearchResponse<T>> {
    if (this.api.searchEntities) {
      return this.api.searchEntities(searchRequest);
    } else {
      return of({total: 0, offset: 0, limit: 0, results: []});
    }
  }


  hasFilters(): boolean {
    if (!this.primeTable) {
      return false;
    }
    if (this._search) {
      return true;
    }
    return !!this.getFilteredByArray().length;
  }

  private getFilteredByArray(): Array<string> {
    if (!this.primeTable) {
      return [];
    }
    const result: Array<string> = [];
    keys(this.filters).forEach((field) => {
      const filterMetadataArr = this.primeTable.filters[field];
      if (isArray(filterMetadataArr) && filterMetadataArr.length) {
        const filterMetadata: FilterMetadata = (filterMetadataArr as FilterMetadata[])[0];
        if (!(filterMetadata.value == null
          || (isArray(filterMetadata.value) && !(filterMetadata.value as Array<any>).length))
        ) {
          result.push(field);
        }
      }
    });
    return result;
  }

  getFilteredByAsString(): string {
    if (!this.primeTable) {
      return '';
    }
    const arr = this.getFilteredByArray().map((field) => this.columnsInfo ? this.columnsInfo.columns[field] || field : field);
    if (this._search) {
      arr.unshift('\u{0276E}Global Search\u{0276F}');
    }
    return arr.join(', ');
  }

  clearFilters(): void {
    // this.primeTable.clearFilterValues(); doesn't set default match mode, the following is used instead
    for (const field of keys(this.filters)) {
      this.filters[field] = [{value: null, matchMode: (this.filters[field] as any)[0].matchMode, operator: 'and'}];
    }
    if (this._search) {
      this._search = undefined;
    }
    this.onClearFilters.emit();
    this.saveState();
    this.load();
  }

  openCreateOrUpdate(rowData: T | null, initialData?: any): void {
    if (this.editUrl && (!this.ignoreEditUrlFor || !(this.ignoreEditUrlFor.includes('newEntity') && !rowData) && !(this.ignoreEditUrlFor.includes('editMenu') && rowData))) {
      this.router.navigate([this.editUrl, rowData?.id ? {id: rowData?.id} : {}]);
    } else if (this.editDialog) {
      const param: IEntityEditorParam = {id: rowData?.id ?? null, data: initialData};
      this.editDialog.show(param, {
        onApply: (data) => this.onCreatedOrUpdated({param, data: data}),
        link: this.editUrl && rowData?.id ? `${this.editUrl};id=${rowData!.id}` : undefined
      });
    }
  }

  openDuplicate(id: string): void {
    if (this.editUrl && (!this.ignoreEditUrlFor || !this.ignoreEditUrlFor.includes('duplicateMenu'))) {
      this.router.navigate([this.editUrl, {id, duplicate: true}]);
    } else if (this.editDialog) {
      const param: IEntityEditorParam = {id, duplicate: true};
      this.editDialog.show(param, {onApply: (data) => this.onCreatedOrUpdated({param, data: data})});
    }
  }

  openBulkEdit(entities: Array<T>): void {
    if (this.bulkEditDialog) {
      const param: IEntityBulkEditorParam = {ids: entities.map((e) => e.id)};
      this.bulkEditDialog.show(param, {onApply: (data) => this.onBulkUpdated({param, data: data})});
    }
  }

  onCreatedOrUpdated(event: { param: IEntityEditorParam; data: T }): void {
    const isNew = event.param.id === null || event.param.duplicate;
    if (isNew) {
      this.data.unshift(event.data);
    } else {
      this.updateDataAndSelection([event.data], {data: 'replace', selection: 'replace'});
    }
  }

  onBulkUpdated(event: { param: IEntityBulkEditorParam; data: Array<T> }): void {
    this.updateDataAndSelection(event.data, {data: 'replace', selection: 'remove'})
  }


  importEntities(fileUploadDialog: FileUploadDialog): void {
    if (this.api.importUrl) {
      fileUploadDialog.show({
        url: this.api.importUrl,
        header: 'Import',
        accept: '.csv',
        onClose: (file?: File) => {
          if (file) {
            this.selection = [];
            this.updateTableMenu();
            this.load();
          }
        }
      });
    }
  }

  exportEntities(): void {
    if (this.api.exportEntities) {
      this.api.exportEntities(omit(this.currentSearchRequest, ['offset', 'limit']));
    }
  }

  archiveEntities(entities: Array<T>): void {
    if (this.api.archiveEntities) {
      this.api.archiveEntities(entities)
        .subscribe((response) => {
          Alert.message({
            severity: 'success',
            summary: 'Success',
            detail: `${response.length} record(s) have been archived`
          });
          this.updateDataAndSelection(response, {data: 'replace', selection: 'remove'});
        });
    }
  }

  patchEntities(entities: Array<T>, patchData: Partial<T>) {
    if (this.api.patchEntities) {
      const fields = keys(patchData);
      const data: Array<IIdentified & Partial<T>> = filter(entities, (entity) => !isEqual(pick(entity, fields), patchData)).map((e) => ({
        id: e.id,
        ...patchData
      }));
      this.api.patchEntities(data)
        .subscribe((response) => {
          Alert.message({
            severity: 'success',
            summary: 'Success',
            detail: `${response.length} record(s) have been updated`
          });
          this.updateDataAndSelection(response, {data: 'replace', selection: 'remove'});
        });
    }
  }


  async deleteEntities(eventTarget: EventTarget, entities: Array<T>) {
    if (this.api.deleteEntities && entities.length) {
      const ids = entities.map((e) => e.id);
      if (await stdConfirm({
        target: eventTarget,
        header: 'Delete Confirmation',
        message: `Are you sure you want to delete ${ids.length > 1 ? ids.length + ' ' : ''}record${ids.length > 1 ? 's' : ''}?`,
      }, 'app-confirm-popup')) {
        this.api!.deleteEntities!(ids)
          .subscribe((response) => {
            Alert.message({
              severity: 'success',
              summary: 'Success',
              detail: `${response.length} record(s) have been deleted`
            });
            this.updateDataAndSelection(response, {data: 'remove', selection: 'remove'});
          });
      }
    }
  }


  protected updateDataAndSelection(entities: Array<T>, type: {
    data: 'replace' | 'remove';
    selection: 'replace' | 'remove'
  }): void {
    let dataUpdated = false;
    let selectionUpdated = false;
    entities.forEach((entity) => {
      let idx = findIndex<IIdentified>(this.data, {id: entity.id});
      if (idx !== -1) {
        if (type.data === 'replace') {
          this.data[idx] = entity;
        } else {
          remove<IIdentified>(this.data, {id: entity.id});
        }
        dataUpdated = true;
      }
      idx = findIndex<IIdentified>(this.selection, {id: entity.id});
      if (idx !== -1) {
        if (type.selection === 'replace') {
          this.selection[idx] = entity;
        } else {
          remove<IIdentified>(this.selection, {id: entity.id});
        }
        selectionUpdated = true;
      }
    });
    if (dataUpdated) {
      this.data = [...this.data];
    }
    if (selectionUpdated) {
      this.selection = [...this.selection];
      this.updateTableMenu();
    }
  }


  trace(...args: any[]): void {
    // console.log(args);
  }
}
