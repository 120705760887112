import {FormControl, FormGroup} from '@angular/forms';
import {IDraftable, IIdentified} from '../api/shared/common';

export type TConstructor<T> = new (...args: any[]) => T;

export type TDifference<EXCL, SRC extends EXCL> = Omit<SRC, keyof Exclude<EXCL, SRC>>;

export type TDifferenceKeys<EXCL, SRC extends EXCL> = keyof TDifference<EXCL, SRC>;

export type TDictionary<T extends Record<string, any>, U = never> = [U] extends [never] ? {
  [prop in keyof T]: T[prop];
} : {
  [prop in keyof T]: U;
}

export type TOptionalDictionary<T extends Record<string, any>, U = never> = {
  [P in keyof TDictionary<T, U>]?: TDictionary<T, U>[P];
}

export type TControlsOf<T extends Record<string, any>> = {
  [K in keyof T]: T[K] extends Record<any, any>
    ? (T[K] extends Array<any> ? FormControl<T[K] | null> : FormGroup<TControlsOf<T[K]>>)
    : FormControl<T[K] | null>;
};

export function hasPropertyWithType<T extends object, P extends keyof T>(
  obj: T,
  propName: P,
  type: string
): boolean {
  return typeof obj === 'object' && obj !== null && propName in obj && typeof obj[propName] === type;
}

export function isInstanceOfIdentified(obj: any): obj is IIdentified {
  return hasPropertyWithType<IIdentified, 'id'>(obj, 'id', 'string');
}

export function isInstanceOfDraftable(obj: any): obj is IDraftable {
  return hasPropertyWithType<IDraftable, 'isDraft'>(obj, 'isDraft', 'boolean');
}

