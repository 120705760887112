import {Directive, OnInit} from '@angular/core';
import {NgControl} from '@angular/forms';
import {isString} from 'lodash';
import {subscriptions} from '../util/util';

@Directive({
  selector: '[nullable]',
  standalone: true
})
export class NullableDirective implements OnInit {
  subscriptions = subscriptions();

  constructor(private ngControl: NgControl) {
  }

  ngOnInit(): void {
    this.subscriptions.add(this.ngControl.valueChanges?.subscribe((value) => {
      if (value != null) {
        if (Array.isArray(value)) {
          if (!value.length) {
            this.ngControl.control?.setValue(null);
          }
        } else if (isString(value)) {
          if (value?.trim() === '') {
            this.ngControl.control?.setValue(null);
          }
        }
      }
    })!);
  }
}
