import {Confirmation} from 'primeng/api/confirmation';
import {Alert} from '../util/alert';
import {Component, inject, Injectable} from '@angular/core';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {CheckboxModule} from 'primeng/checkbox';
import {FormsModule} from '@angular/forms';
import {NgIf} from '@angular/common';

export function stdConfirm(confirmation: Confirmation, key: 'app-confirm-dialog' | 'app-confirm-popup' = 'app-confirm-dialog') {
  return Alert.asyncConfirm({
    ...{
      closeOnEscape: true,
      key,
      acceptButtonStyleClass: 'p-button-text',
      rejectButtonStyleClass: 'p-button',
      icon: 'pi pi-exclamation-triangle mt-text-warn',
    }, ...confirmation
  });
}

export function confirmCancelUnsavedChanges() {
  return stdConfirm({
    header: 'Cancel Confirmation',
    message: `You have unsaved changes. Press Back to go back to editing and save the changes or Discard to discard them and leave the editor.`,
    acceptLabel: 'Discard',
    rejectLabel: 'Back',
    acceptIcon: 'pi pi-times mr-2',
    rejectIcon: 'pi pi-pencil mr-2'
  });
}


@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  exitUnsavedDoNotAskAgain = false;
}

@Component({
  selector: 'app-confirm-exit-unsaved',
  template: `
    <p-confirmDialog key="app-confirm-exit-unsaved"
                     header="Cancel Confirmation"
                     acceptLabel="Discard" rejectLabel="Back"
                     acceptIcon="pi pi-times mr-2" rejectIcon="pi pi-pencil mr-2">
      <ng-template pTemplate="icon" let-message>
      </ng-template>
      <ng-template pTemplate="message" let-message>
        <div class="w-full">
          <div class="flex align-items-center">
            <i class="pi pi-exclamation-triangle mt-text-warn text-4xl mr-2"></i>
            <div class="font-medium">You have unsaved changes. Press Back to go back to editing and save the changes or
              Discard to discard them and leave the editor.
            </div>
          </div>
          <div *ngIf="!setting.exitUnsavedDoNotAskAgain" class="mt-2 text-right">
            <p-checkbox [(ngModel)]="setting.exitUnsavedDoNotAskAgain" [binary]="true"
                        label="Do not ask again"></p-checkbox>
          </div>
        </div>
      </ng-template>
    </p-confirmDialog>
  `,
  imports: [
    ConfirmDialogModule,
    CheckboxModule,
    FormsModule,
    NgIf
  ],
  standalone: true
})
export class ConfirmExitUnsavedComponent {
  setting  = inject(SettingsService);
}


export function confirmExitUnsaved() {
  if (Alert.injector.get(SettingsService).exitUnsavedDoNotAskAgain) {
    return true;
  }
  return stdConfirm({}, 'app-confirm-exit-unsaved' as any);
}

